


<template>
 



          <el-menu
         
              text-color="#000000"
              :default-active="activeIndex"
              class="el-menu-demo parent_div"
              mode="horizontal"
              menu-trigger="click"  
              @select="handleSelect"
              unique-opened="true"
            >
              <el-menu-item index="1" color="#F78F1E"  @click="handleClick"> Home  &nbsp; &nbsp; &nbsp; </el-menu-item>

              <el-sub-menu index="2" color="#F78F1E"  @click="applicationClick"><template #title> About Us   &nbsp; &nbsp; &nbsp;</template>
                <el-sub-menu  index="2-1"><template #title> Oriental Sheet Piling </template>
                    <el-menu-item index="2-1-1" @click="camerachange('orientalhqhome')">HQ Building </el-menu-item>
                    <el-menu-item index="2-1-2" @click="camerachange('ospfactory')">Factory </el-menu-item>
                    <el-menu-item index="2-1-3" @click="camerachange('warehouse')" >Warehouse </el-menu-item>
                  </el-sub-menu >
             
              <el-menu-item index="2-2" @click="camerachange('orbit')">ArcelorMittal Orbit </el-menu-item>
        
             
              </el-sub-menu>

   <!--////////////////// Application Menu open/////////////////////////-->
           
   <el-sub-menu  color="#F78F1E"  index="4"  @click="applicationClick"><template #title> Application   &nbsp; &nbsp; &nbsp;</template>
                 
                <!--////////////////// Building and Basement Menu open/////////////////////////-->
                
                <el-sub-menu  color="#F78F1E" menu-trigger="click"   index="4-1"><template #title> Building and Basement </template>
                  
                   <el-sub-menu  index="4-1-1"><template #title> Permanent Basement </template>
                       
                        <el-menu-item  @click="camerachange('shoppingmall')"  index="4-1-1-1">Mall</el-menu-item >
                      
                    </el-sub-menu >

                    <el-sub-menu  index="4-1-2"><template #title> Temporary Solution</template>
                       
                       <el-menu-item  @click="camerachange('hospital')"  index="4-1-2-1">Hospital</el-menu-item >
                     
                    </el-sub-menu >

                    <el-sub-menu  index="4-1-3"><template #title> Steel Solution</template>
                       
                       <el-menu-item  @click="camerachange('powerstation')"  index="4-1-3-1">Power Plant</el-menu-item >
                     
                    </el-sub-menu >

                    <el-menu-item  @click="camerachange('construction')"  index="4-1-4">Construction Site</el-menu-item >


                </el-sub-menu >
                
                   <!--////////////////// Building and Basement Menu close/////////////////////////-->
          
               
                   <!--////////////////// Canalization, Bridge & Highway Menu open/////////////////////////-->
                
                   <el-sub-menu color="#F78F1E" menu-trigger="click"   index="5-1"><template #title> Canalization, Bridge &amp; Highway  </template>
                    
                      <el-menu-item   @click="camerachange('highway')"  index="5-1-1"><template #title> Highway </template></el-menu-item>
                      <el-menu-item   @click="camerachange('river')"  index="5-1-2"><template #title> River </template></el-menu-item>
                      <el-menu-item   @click="camerachange('bridge')"  index="5-1-3"><template #title> Bridge </template></el-menu-item>
                      <el-menu-item   @click="camerachange('trainstation')"  index="5-1-4"><template #title> Train Station </template></el-menu-item>


                  </el-sub-menu >
              
                  <!--////////////////// Canalization, Bridge & Highway Menu close/////////////////////////-->             


                   <!--////////////////// Port & Jetty Menu open/////////////////////////-->
                
                   <el-sub-menu  index="6-1"><template #title> Port &amp; Jetty </template>
                    
                    <el-menu-item   @click="camerachange('port')"  index="6-1-1"><template #title> Port </template></el-menu-item>
                    <el-menu-item   @click="camerachange('jetty')"  index="6-1-2"><template #title> Jetty </template></el-menu-item>
               


                  </el-sub-menu >
              
                  <!--////////////////// Port & Jetty Menu close/////////////////////////-->             

                  <el-menu-item   @click="camerachange('landreclaim')"  index="7-1"><template #title> Land Reclamation </template></el-menu-item>

             
              </el-sub-menu>
              <!--////////////////// Application Menu close/////////////////////////-->


              <el-sub-menu  index="3" color="#F78F1E" menu-trigger="click" @click="applicationClick"><template #title> Our Connected Partner   &nbsp; &nbsp; &nbsp;</template>
                <el-menu-item index="3-2"  @click="camerachange('ospfactory')">Oriental Steel Pipe </el-menu-item>
                <el-menu-item index="3-3"  @click="camerachange('house')">Oriental Housetop</el-menu-item>
                <el-menu-item index="3-4" @click="camerachange('house')">Oriental Shoring Solutions</el-menu-item>
                <el-menu-item index="3-5"  @click="camerachange('cardealership')">Motor Group</el-menu-item>
                <el-menu-item index="3-6"  @click="camerachange('gasketalley')">Goh Brothers Motor</el-menu-item>
             
             
              </el-sub-menu>
             

              <el-menu-item index="4" color="#F78F1E"  @click="gotomainwebsiteClick"> Go to Main Website   &nbsp; &nbsp; &nbsp; </el-menu-item>
   
              <div style="position:relative;text-align:right;align-content: right;margin-left:auto;margin-right:10px;margin-top:5px;"><img  src="/icons/oriental-castle-small.jpg" width="45" height="45"  /></div> 
           
           
            </el-menu>
           
           

            <el-menu
            v-if="leftmenu"
              default-active="2"
              class="el-menu-vertical-demo parent_div"
              :collapse="isCollapse"
             
              @open="handleOpen"
              @close="handleClose"
            style="height:90vh;width:40vw;margin-top:4px;max-width: 400px;"
            >
            
              <div style="height:95%; position:relative; top:10px">
                <el-scrollbar >
           

                    <el-menu-item style="height:100%;" index="1" @click="camerachange('warehouse')">

                      <table class="tg" style="position:relative; top:-10px; line-height: 1.2;  width:100px;">
                      <thead>
                        <tr>
                          <td class="tg-0lax" rowspan="2" style=" position:relative; top:-17px;  left:-6px;"> <img  src="/icons/warehouse.svg" width="50" height="50"  /></td>
                          <td class="tg-cbs6" style=" position:relative; top:10px"><b>Warehouse</b></td>
                        </tr>
                        <tr>
                          <td class="tg-z9od" style=" position:relative; top:8px; font-size:14px" >
                            Our warehouse facilities, located in Pulau Indah,
                             <br/> Malaysia, as well as in several other countries including 
                             <br/>Singapore, China, and Indonesia, enable us to store 
                             <br/> goods and raw materials prior to production or
                              <br/>shipment to our customers
                            
                            </td>
                        </tr>
                      </thead>
                      </table>
                                        
                    </el-menu-item>

                    <el-menu-item style="height:100%;" index="2" @click="camerachange('factory')">

                        <table class="tg" style="position:relative; top:-10px; line-height: 1.2;  width:100px;">
                        <thead>
                          <tr>
                            <td class="tg-0lax" rowspan="2" style=" position:relative; top:-20px;  left:-6px;"> <img  src="/icons/factory.svg" width="50" height="50"  /></td>
                            <td class="tg-cbs6" style=" position:relative; top:14px"><b>Factory</b></td>
                          </tr>
                          <tr>
                            <td class="tg-z9od" style=" position:relative; top:-5px; font-size:14px" >
                              <br/> This is where we transform raw materials into finished
                              <br/>  products. Our factories are located in Pulau Indah
                              <br/>  and Gelang Patah, Malaysia. A few factory at
                              <br/>  Luxemberg and Shanghai. 
                              
                              </td>
                          </tr>
                        </thead>
                        </table>
                                          
                        </el-menu-item>

                        <el-menu-item style="height:100%;" index="3" @click="camerachange('orientalhqhome')">

                          <table class="tg" style="position:relative; top:-10px; line-height: 1.2;  width:100px;">
                          <thead>
                            <tr>
                              <td class="tg-0lax" rowspan="2" style=" position:relative; top:-20px;  left:-6px;"> <img  src="/icons/apartment.svg" width="50" height="50"  /></td>
                              <td class="tg-cbs6" style=" position:relative; top:14px"><b>Oriental Sheet Piling Group</b></td>
                            </tr>
                            <tr>
                              <td class="tg-z9od" style=" position:relative; top:-5px; font-size:14px" >
                                <br/>Multicultural , inter-generation group of companies,
                                  <br/>teams and individual accross southeast Asia &#38; China 
                                <br/> passionate for the  things we do and commited to 
                                <br/>the project we enagage in. 
                                </td>
                            </tr>
                          </thead>
                          </table>
                                            
                          </el-menu-item>


                          <el-menu-item style="height:100%;" index="4" @click="camerachange('highway')">

                          <table class="tg" style="position:relative; top:-10px; line-height: 1.2;  width:100px;">
                          <thead>
                            <tr>
                              <td class="tg-0lax" rowspan="2" style=" position:relative; top:-8px;  left:-6px;"> <img  src="/icons/highway.png" width="50" height="50"  /></td>
                              <td class="tg-cbs6" style=" position:relative; top:14px"><b>Highway</b></td>
                            </tr>
                            <tr>
                              <td class="tg-z9od" style=" position:relative; top:-5px; font-size:14px" >
                                <br/>Oriental is committed to providing top-notch solutions 
                                <br/>for safe, efficient, and long-lasting highway infrastructure
                                </td>
                            </tr>
                          </thead>
                          </table>
                                            
                          </el-menu-item>


                          <el-menu-item style="height:100%;" index="4" @click="camerachange('shoppingmall')">

                          <table class="tg" style="position:relative; top:-10px; line-height: 1.2;  width:100px;">
                          <thead>
                            <tr>
                              <td class="tg-0lax" rowspan="2" style=" position:relative; top:-18px;  left:-6px;"> <img  src="/icons/shoppingmall.svg" width="50" height="50"  /></td>
                              <td class="tg-cbs6" style=" position:relative; top:14px"><b>Mall ( Basement Parking)</b></td>
                            </tr>
                            <tr>
                              <td class="tg-z9od" style=" position:relative; top:-5px; font-size:14px" >
                                <br/>A well-structured mall with safe parking facilities is 
                                <br/>crucial for attracting customers. We will ensure that
                                <br/> the basement parking is secure
                                </td>
                            </tr>
                          </thead>
                          </table>
                                            
                          </el-menu-item>
               


                          <el-menu-item style="height:100%;" index="4" @click="camerachange('construction')">

                          <table class="tg" style="position:relative; top:-10px; line-height: 1.2;  width:100px;">
                          <thead>
                            <tr>
                              <td class="tg-0lax" rowspan="2" style=" position:relative; top:-8px;  left:-6px;"> <img  src="/icons/traffic_cone.svg" width="50" height="50"  /></td>
                              <td class="tg-cbs6" style=" position:relative; top:14px"><b>Oriental Shoring Solution</b></td>
                            </tr>
                            <tr>
                              <td class="tg-z9od" style=" position:relative; top:-5px; font-size:14px" >
                                <br/>Oriental Shoring Solution (OSS) is a specialized 
                                <br/>underground foundation expert, offering a comprehensive
                                <br/> range of services in temporary excavation and 
                                <br/> earth-retaining stabilizing structure (ERSS) 
                                <br/> construction. 
                                </td>
                            </tr>
                          </thead>
                          </table>
                                            
                          </el-menu-item>


                          <el-menu-item style="height:100%;" index="4" @click="camerachange('powerstation')">

                          <table class="tg" style="position:relative; top:-10px; line-height: 1.2;  width:100px;">
                          <thead>
                            <tr>
                              <td class="tg-0lax" rowspan="2" style=" position:relative; top:-8px;  left:-6px;"> <img  src="/icons/boltelectric.svg" width="50" height="50"  /></td>
                              <td class="tg-cbs6" style=" position:relative; top:14px"><b>Power Plant</b></td>
                            </tr>
                            <tr>
                              <td class="tg-z9od" style=" position:relative; top:-5px; font-size:14px" >
                                <br/>To support the construction of a power plant from the
                                <br/>ground up, we offer efficient and effective solutions. 
                                </td>
                            </tr>
                          </thead>
                          </table>
                                            
                          </el-menu-item>



                          <el-menu-item style="height:100%;" index="4" @click="camerachange('house')">

                        <table class="tg" style="position:relative; top:-10px; line-height: 1.2;  width:100px;">
                        <thead>
                          <tr>
                            <td class="tg-0lax" rowspan="2" style=" position:relative; top:-8px;  left:-6px;"> <img  src="/icons/house.svg" width="50" height="50"  /></td>
                            <td class="tg-cbs6" style=" position:relative; top:14px"><b>House</b></td>
                          </tr>
                          <tr>
                            <td class="tg-z9od" style=" position:relative; top:-5px; font-size:14px" >
                              <br/>We help you to have homes that elevate your lifestyle,
                              <br/>with comfort and convenience at the forefront
                              </td>
                          </tr>
                        </thead>
                        </table>
                                          
                        </el-menu-item>



                        <el-menu-item style="height:100%;" index="4" @click="camerachange('trainstation')">

                        <table class="tg" style="position:relative; top:-10px; line-height: 1.2;  width:100px;">
                        <thead>
                          <tr>
                            <td class="tg-0lax" rowspan="2" style=" position:relative; top:-8px;  left:-6px;"> <img  src="/icons/train.svg" width="50" height="50"  /></td>
                            <td class="tg-cbs6" style=" position:relative; top:14px"><b>Train Station</b></td>
                          </tr>
                          <tr>
                            <td class="tg-z9od" style=" position:relative; top:-5px; font-size:14px" >
                              <br/>For seamless project implementation, we offer a tailored 
                              <br/>solution package that ensures reliable product delivery
                              </td>
                          </tr>
                        </thead>
                        </table>
                                          
                        </el-menu-item>



                        <el-menu-item style="height:100%;" index="4" @click="camerachange('hospital')">

                      <table class="tg" style="position:relative; top:-10px; line-height: 1.2;  width:100px;">
                      <thead>
                        <tr>
                          <td class="tg-0lax" rowspan="2" style=" position:relative; top:-14px;  left:-6px;"> <img  src="/icons/medicine.svg" width="50" height="50"  /></td>
                          <td class="tg-cbs6" style=" position:relative; top:14px"><b>Hospital </b></td>
                        </tr>
                        <tr>
                          <td class="tg-z9od" style=" position:relative; top:-5px; font-size:14px" >
                            <br/>To support the construction of the hospital, our primary
                            <br/> objective is to ensure a safe, stable, and secure structure 
                            <br/> that prioritizes the safety and well-being of the community.
                            </td>
                        </tr>
                      </thead>
                      </table>
                                        
                      </el-menu-item>

 
                      <el-menu-item style="height:100%;" index="4" @click="camerachange('port')">

                      <table class="tg" style="position:relative; top:-10px; line-height: 1.2;  width:100px;">
                      <thead>
                        <tr>
                          <td class="tg-0lax" rowspan="2" style=" position:relative; top:-18px;  left:-10px;"> <img  src="/icons/cargo-ship.png" width="50" height="50"  /></td>
                          <td class="tg-cbs6" style=" position:relative; top:14px"><b>Port </b></td>
                        </tr>
                        <tr>
                          <td class="tg-z9od" style=" position:relative; top:-5px; font-size:14px" >
                            <br/>We offer advanced solutions for port projects,
                            <br/> ensuring robust harbor walls. Our focus is on
                            <br/>  delivering the best products and services for 
                            <br/>  the project's success
                            </td>
                        </tr>
                      </thead>
                      </table>
                                        
                      </el-menu-item>




                      <el-menu-item style="height:100%;" index="4" @click="camerachange('ospfactory')">

                      <table class="tg" style="position:relative; top:-10px; line-height: 1.2;  width:100px;">
                      <thead>
                        <tr>
                          <td class="tg-0lax" rowspan="2" style=" position:relative; top:-8px;  left:-6px;"> <img  src="/icons/pipe_icon.png" width="50" height="50"  /></td>
                          <td class="tg-cbs6" style=" position:relative; top:14px"><b>Steel Pipes </b></td>
                        </tr>
                        <tr>
                          <td class="tg-z9od" style=" position:relative; top:-5px; font-size:14px" >
                            <br/>This is where we transform raw materials
                            <br/> into finished products. 
                            </td>
                        </tr>
                      </thead>
                      </table>
                                        
                      </el-menu-item>


     
                      <el-menu-item style="height:100%;" index="4" @click="camerachange('cardealership')">

                      <table class="tg" style="position:relative; top:-10px; line-height: 1.2;  width:100px;">
                      <thead>
                        <tr>
                          <td class="tg-0lax" rowspan="2" style=" position:relative; top:-24px;  left:-6px;"> <img  src="/icons/car-icon.png" width="50" height="50"  /></td>
                          <td class="tg-cbs6" style=" position:relative; top:14px"><b>Car Showroom </b></td>
                        </tr>
                        <tr>
                          <td class="tg-z9od" style=" position:relative; top:-5px; font-size:14px" >
                            <br/>Discover the extensive range of Lexus vehicles
                            <br/> available in Malaysia and take the latest SUVs,
                            <br/>  sedans, and coupes for a test drive.
                            <br/>   Our dealerships are located in Selangor and
                            <br/>    Perak, Malaysia
                            </td>
                        </tr>
                      </thead>
                      </table>
                                        
                      </el-menu-item>                      



                      <el-menu-item style="height:100%;" index="4" @click="camerachange('gasketalley')">

                      <table class="tg" style="position:relative; top:-10px; line-height: 1.2;  width:100px;">
                      <thead>
                        <tr>
                          <td class="tg-0lax" rowspan="2" style=" position:relative; top:-20px;  left:-6px;"> <img  src="/icons/motorcycle.svg" width="50" height="50"  /></td>
                          <td class="tg-cbs6" style=" position:relative; top:14px"><b>Shop (The Gasket Alley)</b></td>
                        </tr>
                        <tr>
                          <td class="tg-z9od" style=" position:relative; top:-5px; font-size:14px" >
                            <br/>Gasket Alley is a premier destination for automotive 
                            <br/>enthusiasts in Malaysia and the region, boasting
                            <br/>  a one-of-a-kind, vibrant atmosphere
                            <br/>  that sets it apart from the rest
                            </td>
                        </tr>
                      </thead>
                      </table>
                                        
                      </el-menu-item>      




                      <el-menu-item style="height:100%;" index="4" @click="camerachange('orbit')">

                    <table class="tg" style="position:relative; top:-10px; line-height: 1.2;  width:100px;">
                    <thead>
                      <tr>
                        <td class="tg-0lax" rowspan="2" style=" position:relative; top:-20px;  left:-6px;"> <img  src="/icons/tokyo-tower.png" width="50" height="50"  /></td>
                        <td class="tg-cbs6" style=" position:relative; top:14px"><b>ArcelorMittal Orbit</b></td>
                      </tr>
                      <tr>
                        <td class="tg-z9od" style=" position:relative; top:-5px; font-size:14px" >
                          <br/>ArcelorMittal is the largest steel manufactures
                          <br/> at North America, South America and Europe. 
                          <br/>Made of 35,000 bolts and enough steel to make
                          <br/>265 double-decker buses
                          </td>
                      </tr>
                    </thead>
                    </table>
                                      
                    </el-menu-item>   



                    <el-menu-item style="height:100%;" index="4" @click="camerachange('bridge')">

                    <table class="tg" style="position:relative; top:-10px; line-height: 1.2;  width:100px;">
                    <thead>
                      <tr>
                        <td class="tg-0lax" rowspan="2" style=" position:relative; top:-20px;  left:-6px;"> <img  src="/icons/bridge.png" width="50" height="50"  /></td>
                        <td class="tg-cbs6" style=" position:relative; top:14px"><b>Bridge</b></td>
                      </tr>
                      <tr>
                        <td class="tg-z9od" style=" position:relative; top:-5px; font-size:14px" >
                          <br/>For bridge projects, the support structure must be
                          <br/> strong and of the highest quality. At Oriental, 
                          <br/> we provide solutions to ensure that the structure is 
                          <br/> stable and safe to use
                          </td>
                      </tr>
                    </thead>
                    </table>
                                      
                    </el-menu-item>   

                    


                    <el-menu-item style="height:100%;" index="4" @click="camerachange('landreclaim')">

                  <table class="tg" style="position:relative; top:-10px; line-height: 1.2;  width:100px;">
                  <thead>
                    <tr>
                      <td class="tg-0lax" rowspan="2" style=" position:relative; top:-16px;  left:-6px;"> <img  src="/icons/island.png" width="50" height="50"  /></td>
                      <td class="tg-cbs6" style=" position:relative; top:14px"><b>Land Reclamation</b></td>
                    </tr>
                    <tr>
                      <td class="tg-z9od" style=" position:relative; top:-5px; font-size:14px" >
                        <br/>Stable land reclamation and environmental
                        <br/> protection are vital. Our solutions ensure 
                        <br/> minimal impact on the environment
                        </td>
                    </tr>
                  </thead>
                  </table>
                                    
                  </el-menu-item>   


                  <el-menu-item style="height:100%;" index="4" @click="camerachange('petrotankplant')">

                  <table class="tg" style="position:relative; top:-10px; line-height: 1.2;  width:100px;">
                  <thead>
                    <tr>
                      <td class="tg-0lax" rowspan="2" style=" position:relative; top:-16px;  left:-6px;"> <img  src="/icons/refinery.png" width="50" height="50"  /></td>
                      <td class="tg-cbs6" style=" position:relative; top:14px"><b>Petro Tank Plant</b></td>
                    </tr>
                    <tr>
                      <td class="tg-z9od" style=" position:relative; top:-5px; font-size:14px" >
                        <br/>Oriental Sheet Piling have played a crucial 
                        <br/>role in assisting the establishment of petrol 
                        <br/>tank plants through land reclamation efforts.
                        </td>
                    </tr>
                  </thead>
                  </table>
                                    
                  </el-menu-item>   




                  <el-menu-item style="height:100%;" index="4" @click="camerachange('river')">

                    <table class="tg" style="position:relative; top:-10px; line-height: 1.2;  width:100px;">
                    <thead>
                      <tr>
                        <td class="tg-0lax" rowspan="2" style=" position:relative; top:-16px;  left:-6px;"> <img  src="/icons/river.png" width="50" height="50"  /></td>
                        <td class="tg-cbs6" style=" position:relative; top:14px"><b>River</b></td>
                      </tr>
                      <tr>
                        <td class="tg-z9od" style=" position:relative; top:-5px; font-size:14px" >
                          <br/>With smart solutions, floods can be prevented.
                          <br/> This ensures a safer and more secure living 
                          <br/> environment and surroundings
                          </td>
                      </tr>
                    </thead>
                    </table>
                                      
                    </el-menu-item>   




                    <el-menu-item style="height:100%;" index="4" @click="camerachange('jetty')">

                    <table class="tg" style="position:relative; top:-10px; line-height: 1.2;  width:100px;">
                    <thead>
                      <tr>
                        <td class="tg-0lax" rowspan="2" style=" position:relative; top:-16px;  left:-6px;"> <img  src="/icons/jetty_bw.png" width="50" height="50"  /></td>
                        <td class="tg-cbs6" style=" position:relative; top:14px"><b>Jetty</b></td>
                      </tr>
                      <tr>
                        <td class="tg-z9od" style=" position:relative; top:-5px; font-size:14px" >
                          <br/>We have also participated in the Jetty project, 
                          <br/>offering effective solutions for soil retention
                          <br/> and erosion prevention in marine environments
                          </td>
                      </tr>
                    </thead>
                    </table>
                                      
                    </el-menu-item>   



                  </el-scrollbar>
                </div>
            </el-menu>


          
   <el-card v-if="selectedmenus != '' && selectedmenus != 'orientalhq' && descVisible != 'false'" class="box-card child_div1" style=" position:absolute;  height:80vh; max-width:400px; width:70%; right:10px; top:63px; ">
 <div style="height:80vh;">
 <el-scrollbar max-height="75vh" >   



 
   
    
    <el-row style="text-align:center;  align-content: center; display: block; width:100%;" >



              <div style="position:relative;text-align:right;align-content: right;">   <img @click="closeDesc()" style="z-index:3" src="/icons/close_icons.svg" width="30" height="30"  /></div>
        
        
        
        
        
          <div style="position:relative;top:-30px;text-align: center;justify-content: center; font-size: 22px; width:100%; z-index:-1; ">
            
            <div v-if="selectedmenus == 'warehouse'"><b>Warehouse</b> <br/>  <img  src="/icons/warehouse.svg" width="90" height="90" style="margin-bottom: -30px;" /> </div>
            <div v-else-if="selectedmenus == 'factory'"><b>Factory</b> <br/>  <img  src="/icons/factory.svg" width="90" height="90" style="margin-bottom: -30px; " /> </div>
            <div v-else-if="selectedmenus == 'orientalhqhome'"> <b>Oriental Sheet Piling HQ</b>  <br/>  <img  src="/icons/osp_logo.png"  style="margin-bottom: -30px;  width:70%; height:70%;" />  </div>
            <div v-else-if="selectedmenus == 'highway'"> <b>Highway</b> <br/> <br/>  <img  src="/icons/highway.png" width="90" height="90" style="margin-bottom: -30px;" />  </div>
            <div v-else-if="selectedmenus == 'shoppingmall'"><b>Shopping Mall</b>  <br/>  <img  src="/icons/shoppingmall.svg" width="90" height="90" style="margin-bottom: -30px;" /> </div>
            <div v-else-if="selectedmenus == 'construction'"> <b>Oriental Shoring Solution </b><br/>  <img  src="/icons/orientalshoringsolution_logo.png" style="margin-bottom: -30px;width:40%; height:40%;" /></div>
            <div v-else-if="selectedmenus == 'powerstation'"><b>Power Plant</b> <br/>  <img  src="/icons/boltelectric.svg" width="90" height="120" style="margin-bottom: -20px;" /></div>
            <div v-else-if="selectedmenus == 'house'"><b>Oriental Housetop</b>  <br/>  <img  src="/icons/house.svg" width="90" height="90" style="margin-bottom: -30px;" /> </div>
            <div v-else-if="selectedmenus == 'trainstation'"> <b>Train Station</b> <br/>  <img  src="/icons/train.svg" width="90" height="90" style="margin-bottom: -30px;" /></div>
            <div v-else-if="selectedmenus == 'hospital'"><b>Hospital</b><br/>  <img  src="/icons/medicine.svg" width="90" height="90" style="margin-bottom: -30px;" /> </div>
            <div v-else-if="selectedmenus == 'port'"> <b>Port</b> <br/>  <img  src="/icons/cargo-ship.png" width="90" height="90" style="margin-bottom: -30px;" /></div>
            <div v-else-if="selectedmenus == 'ospfactory'"> <b>Oriental Steel Pipes </b><br/>  <img  src="/icons/ospipe_logo2.png"  style="margin-bottom: -30px;  width:70%; height:70%;" /> </div>
            <div v-else-if="selectedmenus == 'cardealership'"> <b>Car Showroom</b><br/>  <img  src="/icons/car-icon.png" width="90" height="90" style="margin-bottom: -30px;" /> </div>
            <div v-else-if="selectedmenus == 'gasketalley'"><b> Gasket Alley</b><br/>  <img  src="/icons/motorcycle.svg" width="90" height="90" style="margin-bottom: -30px;" /> </div>
            <div v-else-if="selectedmenus == 'orbit'"> <b>ArcelorMittal Orbit</b><br/><br/>  <img  src="/icons/oriental_sheet_piling_arcelormittal.png"  style="margin-bottom: -30px; width:80%; height:100%;" />  </div>
            <div v-else-if="selectedmenus == 'landreclaim'"><b>Land Reclaimation </b> <br/>  <img  src="/icons/island.png" width="90" height="90" style="margin-bottom: -30px;" /></div>  
            <div v-else-if="selectedmenus == 'petrotankplant'"><b>Petro Tank Plant </b>  <br/>  <img  src="/icons/refinery.png" width="90" height="90" style="margin-bottom: -30px;" /></div>  
            <div v-else-if="selectedmenus == 'bridge'"><b>Bridge</b>   <br/>  <img  src="/icons/bridge.png" width="90" height="90" style="margin-bottom: -30px;" />  </div>  
            <div v-else-if="selectedmenus == 'river'"><b>River</b>   <br/>  <img  src="/icons/river.png" width="90" height="90" style="margin-bottom: -30px;" />  </div>  
            <div v-else-if="selectedmenus == 'jetty'"><b>Jetty</b>   <br/>  <img  src="/icons/jetty_bw.png" width="90" height="90" style="margin-bottom: -30px;" />  </div>  
        </div>




            </el-row>


            <el-row style="text-align:center;align-content: center; display: block;width:100%;">
        

              <el-col :span="24" justify="space-evenly" style="text-align:center;align-content: center; display: block; min-width:200px; width:100%;">
            
        <!--  

          <div v-if="selectedMenu == ''">        

                  <img src="/icons/oriental-castle-group.jpg" width="200" alt="Oriental Castle Group" style="width:200px; " > 

              </div>  

        -->
        
        <div v-if="selectedmenus == 'About Us'">        
          <img  src="/icons/oriental-castle-group.jpg" width="200" height="200"  />
          <el-collapse v-model="activeName" accordion  >
              <el-collapse-item name="1">
                <template #title >   </template>
                <div  style="text-align:left;align-content: left; display: block;">
                  What Makes Oriental Castle a great group to work with?<p></p>
                  Your path to walk; our privilege to guide.<p></p>

                  Whether your calling is in making a difference or making beautiful charts, we have the resources and drive to develop your passions, further your potential, and grow you as a 21st century citizen.
              
                </div>
            
              </el-collapse-item>

            
              <el-collapse-item name="2">
                <template #title > Clear skies,full hearts,can't lose.   </template>
                <div  style="text-align:left;align-content: left; display: block;">
                  Recognising the importance of a positive and encouraging environment to work, play and grow, we’re in the business of fostering a positive, exciting and engaging environment for our people.
                </div>
            
              </el-collapse-item>

          
          </el-collapse>
      </div>  




      <div v-if="selectedmenus == 'Our Businesses'">        
          <img  src="/icons/oriental_sheet_piling_arcelormittal.png" width="200"  /> <br/>
          <el-collapse v-model="activeName" accordion  >
              <el-collapse-item name="1">
                <template #title >  Oriental Sheet Piling </template>
                <div  style="text-align:left;align-content: left; display: block;">
                  The one that started it all: OSP began by first supplying steel, and eventually supplying a service – one that is uncompromising on quality and efficacy when it comes to the most demanding foundation projects.   </div>
            
              </el-collapse-item>

          </el-collapse>
      </div>  








  <div v-if="selectedmenus == 'warehouse'">        
         

        <el-collapse v-model="activeName" accordion  >
              
                    <el-collapse-item name="1" style="margin-bottom:2px;">
                      <template #title >  Description </template>
                    
                      <div  style="text-align:left;align-content: left; display: block;">
                        The Oriental Sheet Piling warehouse in Pulau Indah is a central hub for storing and delivering high-quality sheet piles for construction projects in the area. 
                        <br/><br/>
                        Pulau Indah, in Malaysia, was chosen for its strategic location near important transportation networks like ports and highways. 
                        This makes it easy and cost-effective to move goods to construction sites in Malaysia and nearby areas. 
                  
                      </div>
                    </el-collapse-item>

      
             
                        <el-collapse-item name="2" style="margin-bottom:2px;">
                          <template #title > Address:   </template>
                          <el-collapse v-model="activeName2" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;"  >
                              
                            
                            <el-collapse-item name="2-1">
                                <template #title >Pulau Indah  </template>
                                
                                <div  style="text-align:left;align-content: left; display: block;">
                                  Lebuh Sungai Chandong, Pulau Indah Industrial Park, 42000 Pulau Indah, Selangor 
                                  
                                  
                                  
                                </div>
                
                            </el-collapse-item>
                                
                            <el-collapse-item name="2-2">
                                <template #title >Telephone number : </template>
                                
                                <div  style="text-align:left;align-content: left; display: block;">
                                  03-31023423
                                  
                                </div>
                
                            </el-collapse-item>
                
                          </el-collapse>
            
              </el-collapse-item>
      
      
        </el-collapse>
    
   </div>  







      <div v-if="selectedmenus == 'factory'">        
          <el-collapse v-model="activeName" accordion  >
            
            <el-collapse-item name="1">
                <template #title >  What We Do? </template>
                <div  style="text-align:left;align-content: left; display: block;">
                  We produce and customize high-quality sheet piles, delivering them directly to construction sites. We offer guidance and support to help clients, engineers, and contractors choose the right sheet piles and understand how to use them effectively.  
                
                </div>
            
              </el-collapse-item>

            
              <el-collapse-item name="2">
                <template #title >  Address: Pulau Indah </template>
            
                <div  style="text-align:left;align-content: left; display: block;">
                
                  Lebuh Sungai Chandong, Pulau Indah Industrial Park, 42000 Pulau Indah, Selangor  tel 03-31023423
                
                </div>

            
              </el-collapse-item>

              <el-collapse-item name="3">
                <template #title >  Address: China  </template>
            
                <div  style="text-align:left;align-content: left; display: block;">
                
                  No 213, Jinshi Road, Boshan, Shanghai, China.  Tel: +862131261833
                
                </div>

            
              </el-collapse-item>

              <el-collapse-item name="4">
                <template #title >  Address: Indonesia   </template>
            
                <div  style="text-align:left;align-content: left; display: block;">
                
                  Blok EF, Alam Sutera, Ruko De Mansion, Jl. Jalur Sutera Tim. No.20, RT.004/RW.011, Kunciran Jaya, Pinang, Tangerang City, Banten 15144, Indonesia
    Tel 639567318401
                
                </div>

            
              </el-collapse-item>


              <el-collapse-item name="5">
                <template #title >  Address: Singapore    </template>
            
                <div  style="text-align:left;align-content: left; display: block;">
                
                  18 Howard Road, #10-04 Novelty Bizcenter, 369585 Singapore.
    Tel: +6564636646
                
                </div>

              </el-collapse-item>

            </el-collapse>

      </div>  










      <div v-if="selectedmenus == 'orientalhqhome'"> 
      
        
        <el-collapse v-model="activeName" accordion  >
              
              <el-collapse-item name="1" style="margin-bottom:2px;">
                <template #title > Who We Are </template>
               
                <div  style="text-align:left;align-content: left; display: block;">
                  We are a leading provider of steel sheet piling solutions, offering a comprehensive range of products, services, and expertise to meet the diverse needs of construction projects across Asia. Established in 1999, we have consistently set 
                  the benchmark for quality and innovation, providing contractors, developers, and engineers with exceptional value and groundbreaking solutions.
      
                </div>
              </el-collapse-item>



              <el-collapse-item name="2" style="margin-bottom:2px;">
                <template #title > What We Do  </template>
               
                <div  style="text-align:left;align-content: left; display: block;">
                  We provide custom sheet piles and installation services for construction projects. We offer consultation, design, and technical support
                   to ensure that our sheet pile solutions meet your needs. We also prioritize environmental sustainability.
                </div>
              </el-collapse-item>
             
             <el-collapse-item name="3" accordion style="margin-bottom:2px;">
                <template #title >  Where We Are  </template>

                    <el-collapse v-model="activeName2" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;"  >
                            <el-collapse-item name="3-1">
                                  <template #title > Head Office    </template>
                                  
                                    <el-collapse v-model="activeName3" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;"  >
                                      
                                      <el-collapse-item name="3-1-1" accordion>
                                          <template #title > Malaysia (HQ)   </template>
                                          <div  style="text-align:left;align-content: left; display: block;">
                                            Oriental Sheet Piling Sdn. Bhd 
                                            <br/>
                                            Block I, Unit 11, Dataran Prima, Jalan PJU 1/37, 47301 Petaling Jaya, Selangor Darul Ehsan,Malaysia
                                          </div>
                                        </el-collapse-item>

                                        <el-collapse-item name="3-1-2" accordion>
                                          <template #title > China (HQ)  </template>
                                          <div  style="text-align:left;align-content: left; display: block;">
                                            Oriental Sheet Piling Sdn. Bhd 
                                            <br/>
                                            Oriental Sheet Piling (Shanghai) Leasing Co. ltd.
                                              Room 2601, 357 Songlin Road, Pudong Area, Shanghai, China
                                          </div>
                                        </el-collapse-item>
                                  
                                    </el-collapse>
                              </el-collapse-item>
                    
      
                     
                                <el-collapse-item name="3-2" accordion>
                                      <template #title > Manufacturing Plant    </template>
                                      
                                        <el-collapse v-model="activeName3" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;"  >
                                          
                                          <el-collapse-item name="3-2-1" accordion>
                                              <template #title >China   </template>
                                              <div  style="text-align:left;align-content: left; display: block;">
                                                Oriental Heavy Gauge Cold Forming (Shanghai) Company Ltd. 
                                                <br/>
                                                No 213, Jinshi Road Baoshan, Shanghai, China. 
                                              </div>
                                            </el-collapse-item>

                                            <el-collapse-item name="3-2-2" accordion>
                                              <template #title >Indonesia   </template>
                                              <div  style="text-align:left;align-content: left; display: block;">
                                                PT. Oriental Sheet Piling  Oriental Sheet Piling Sdn. Bhd 
                                                <br/>                                                                          
                                                Ruko De Mansion, Jl. Jalur Sutra,
                                                Kelurahan Kuciran Jaya, Pinang, Tangerang, Banten 15144, Indonesia 
                                              </div>
                                            </el-collapse-item>

                                            <el-collapse-item name="3-2-3">
                                              <template #title >Philippines  </template>
                                              <div  style="text-align:left;align-content: left; display: block;">
                                                Oriental SP Inc. 
                                                <br/>                                                                          
                                                IT Office 
                                                8th Floor, Sunlife Centre,
                                                5th Avenue Corner Rizal Drive, Banofacio Global City, Taguig, Metro Manila 1634, Philippines 
                                              </div>
                                            </el-collapse-item>


                                      
                                        </el-collapse>
                                  </el-collapse-item>
                     



                    
                              <el-collapse-item name="3-3" accordion>
                                    <template #title > Asean    </template>
                                    
                                      <el-collapse v-model="activeName3" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;"  >
                                        
                                        <el-collapse-item name="3-3-1" accordion>
                                            <template #title >Thailand   </template>
                                            <div  style="text-align:left;align-content: left; display: block;">
                                              Oriental Sheet Piling Co.Ltd 
                                              <br/> 
                                              219/2, Asoke Towers, 
                                              Sukhumvit 21 Road North Khlongtoey,
                                              Wattana, Bangkok 10110,
                                              Thailand 
                                            </div>
                                          </el-collapse-item>

                                          <el-collapse-item name="3-3-2" accordion>
                                            <template #title >Singapore   </template>
                                            <div  style="text-align:left;align-content: left; display: block;">
                                              Oriental Sheet Piling Pte. Ltd 
                                              <br/>
                                              18 Howard Road,
                                              #10-04 Novelty Bizcenter, 
                                              369585 Singapore 
                                            </div>
                                          </el-collapse-item>

                                          <el-collapse-item name="3-3-3">
                                            <template #title >Vietnam </template>
                                            <div  style="text-align:left;align-content: left; display: block;">
                                              Oriental Sheet Piling Co. Ltd 
                                              <br/>
                                              Lot 2, Tan Tao Industrial Park Street C, Ward Tan Tao, Binh Tan District, 
                                              Ho Chi Minh City, Vietnam 
                                            </div>
                                          </el-collapse-item>

                                    

                                    
                                      </el-collapse>
                                </el-collapse-item>
                      


                    
                              <el-collapse-item name="3-4" accordion>
                                    <template #title > China Office  </template>
                                    
                                      <el-collapse v-model="activeName3" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;"  >
                                        
                                        <el-collapse-item name="3-4-1">
                                            <template #title >GuangZhou </template>
                                            <div  style="text-align:left;align-content: left; display: block;">
                                              Oriental Sheet Piling  
                                              <br/>
                                              Room 304, Yayuan Building, 
                                              No. 397, Donghuan Road, Shiqiao Street, Panyu District, GuangZhou, 511400 China
                                            </div>
                                          </el-collapse-item>

                                          <el-collapse-item name="3-4-2" accordion>
                                            <template #title >Wuhan   </template>
                                            <div  style="text-align:left;align-content: left; display: block;">
                                              Oriental Sheet Piling 
                                              <br/>
                                            No. 1 Nuli Village Baiyu Hill Qingshan District, Wuhan, Hubei Province, 430013, China
                                            </div>
                                          </el-collapse-item>

                                          <el-collapse-item name="3-4-3" accordion>
                                            <template #title >Hong Kong    </template>
                                            <div  style="text-align:left;align-content: left; display: block;">
                                              Oriental Sheet Piling (China) Co, Ltd
                                            Room 2301, 
                                            No.88 Hing Fat Building Causeway Bay, Hong Kong 
                                            </div>
                                          </el-collapse-item>                    

                                    
                                      </el-collapse>
                                </el-collapse-item>
                


                              </el-collapse>



      
              </el-collapse-item>
      
      
              
        <el-collapse-item name="4" style="margin-bottom:2px;">
          <template #title >Contact us   </template>
          <el-collapse v-model="activeName2" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;"  >
            
                  <el-collapse-item name="4-1">

                  <template #title >Website </template>
                 <div  style="text-align:left;align-content: left; display: block;">
                    www.orientalsheetpiling.com
                    </div>

                    </el-collapse-item>   

                  <el-collapse-item name="4-2">

                  <template #title >Sales Inquiries Email </template>
                          <el-collapse v-model="activeName2" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;"  >
                              
                                    <el-collapse-item name="4-2-1">

                                            <template #title >ASEAN </template>

                                              <div  style="text-align:left;align-content: left; display: block;">
                                                enquiry@orientalcastle.com
                                              </div>

                                    </el-collapse-item>   

                                    <el-collapse-item name="4-2-2">

                                            <template #title >China </template>

                                              <div  style="text-align:left;align-content: left; display: block;">
                                                enquiry.china@orientalcastle.com
                                              </div>

                                      </el-collapse-item>   

                        </el-collapse>
                  </el-collapse-item>                              
                
              </el-collapse>
        </el-collapse-item>   
      





            
              <el-collapse-item name="5" style="margin-bottom:2px;">
                <template #title >Career  </template>
               
                <div  style="text-align:left;align-content: left; display: block;">
                  Start your professional journey with us today!
                  <br/><br/>
                Visit: www.orientalsheetpiling.com
                </div>

              </el-collapse-item>   


            </el-collapse>



      </div>  




















      <div v-if="selectedmenus == 'highway'">        
    
 <el-collapse v-model="activeName" accordion  >
              
        <el-collapse-item name="1" style="margin-bottom:2px;">
          <template #title >  Description </template>
         
          <div  style="text-align:left;align-content: left; display: block;">
            We used strong and versatile steel sheet piles to build a durable and efficient highway. 
            <br/><br/>
            The steel sheet piles formed the foundation, retaining walls, and erosion control barriers, making the highway stable and long-lasting.
             We wanted to build a highway that would make transportation easy and last for many years.

          </div>
        </el-collapse-item>

       
        <el-collapse-item name="2" style="margin-bottom:2px;">
          <template #title >  Common Solution  </template>
          <el-collapse v-model="activeName2" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;"  >
              
            
            <el-collapse-item name="2-1">
                 <template #title >  Deep Excavation  </template>
                
                <div  style="text-align:left;align-content: left; display: block;">
                  Our focus was on carefully and safely digging deep into the earth to achieve our construction goals. 
                  <br/><br/>
                  We implemented meticulous planning and precise techniques to manage the challenges of working below ground level. 
                  Our primary objective was to ensure the safety of our team and the integrity of surrounding structures while achieving the desired depth and scope of the excavation. 
                </div>

             </el-collapse-item>

            
             <el-collapse-item name="2-2">
                 <template #title >  Temporary Cofferdam  </template>
                
                <div  style="text-align:left;align-content: left; display: block;">
                  To provides a reliable, efficient, and cost-effective solution for creating a dry work area, facilitating construction activities below the water table while ensuring worker safety and minimizing project delays
                </div>

             </el-collapse-item>

          </el-collapse>

        </el-collapse-item>

        
        <el-collapse-item name="3" style="margin-bottom:2px;">
          <template #title > Product and Service  </template>
          <el-collapse v-model="activeName2" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;" >
              
            
            <el-collapse-item name="3-1">
                 <template #title > Steel Sheet Pile </template>
                
                <div  style="text-align:left;align-content: left; display: block;">
                  Driven vertically into the ground to create a barrier that prevents soil erosion and supports the highway infrastructure. 
                  <br/><br/>
                  They provide stability by holding back soil and preventing landslides or slope failures
                </div>

             </el-collapse-item>

            
             <el-collapse-item name="3-2">
                 <template #title > Pipe  Struts  </template>
                
                <div  style="text-align:left;align-content: left; display: block;">
                  Provide essential access and connectivity for vehicles entering and exiting the parking area. 
                  Tie roads in basement mall parking projects are designed to efficiently guide vehicles to designated parking spaces and ensure smooth traffic flow within the facility.
                </div>

             </el-collapse-item>

             
             <el-collapse-item name="3-3">
                 <template #title > Tie Rod </template>
                
                <div  style="text-align:left;align-content: left; display: block;">
                  To bolster and reinforce the sheet piles, playing a crucial role in maintaining their alignment, stability, and structural integrity.
                  <br/><br/>
                  By effectively preventing lateral movement and preserving the desired spacing and alignment, tie rods ensure a robust foundation for the hospital, contributing significantly to the overall safety and stability of the structure.    
                  </div>

             </el-collapse-item>


             <el-collapse-item name="3-4">
                 <template #title > Beam  </template>
                
                <div  style="text-align:left;align-content: left; display: block;">
                  Commonly used in structural frameworks, support systems, and equipment installations. 
                  <br/><br/>
                They provide load-bearing capacity and structural integrity to the power plant's buildings, including turbine halls, boiler structures, and control rooms.
                
                </div>

             </el-collapse-item>

          </el-collapse>

        </el-collapse-item>

      </el-collapse>
      </div>  





      <div v-if="selectedmenus == 'shoppingmall'">        
          <el-collapse v-model="activeName" accordion  >
              
            <el-collapse-item name="1">
              
                <template #title > Description  </template>
                <div  style="text-align:left;align-content: left; display: block;">
                  Oriental Sheet Piling serve as a vital structural element for excavation support,  waterproofing, foundation stability, and space optimization.
                  <br/> <br/>
                  These strong and versatile piles provide the necessary structural integrity to create secure retaining walls,                  
                   ensuring safety for shoppers and the long-term viability of the underground space.
              </div>
                </el-collapse-item>

          
           
                <el-collapse-item name="2" style=" align-content: center; text-align: center; display: block; align-items: center ; align-self: center; margin-bottom:2px;" >
                <template #title >Common Solution </template>
               
                
               <el-collapse v-model="activeName2"   accordion  style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;" >
                  

                <el-collapse-item name="2-1" style="margin-bottom:2px;">
                      <template #title >Permanent work  </template>
                      <br/>
                          <div  style="text-align:left;align-content: left; display: block;">
                            We focused on creating long-lasting solutions. Our efforts were dedicated to building infrastructure that stands the test of time, meeting the needs of today and tomorrow. 
                          </div>
                </el-collapse-item>
                      
                <el-collapse-item name="2-2" style="margin-bottom:2px;">
                      <template #title >Deep Excavation   </template>
                      <br/>
                          <div  style="text-align:left;align-content: left; display: block;">
                            Our focus was on carefully and safely digging deep into the earth to achieve our construction goals. We implemented meticulous planning and precise techniques to manage the challenges of working below ground level. 
                            
                            <br/><br/>
                            Our primary objective was to ensure the safety of our team and the integrity of surrounding structures while achieving the desired depth and scope of the excavation. 
                          </div>
                </el-collapse-item>
                      
                <el-collapse-item name="2-3" style="margin-bottom:2px;">
                      <template #title >Double Walls   </template>
                      <br/>
                          <div  style="text-align:left;align-content: left; display: block;">
                            Double-walled retaining walls, comprised of two parallel walls with a space in between.
                            <br/><br/>
                             They effectively control erosion along waterfronts, provide robust load-bearing support for heavy cargo and structures, offer protection against wave action, optimize space utilization, ensure foundation stability, and can be tailored to specific project needs
                          
                          </div>
                </el-collapse-item>
                  

                   </el-collapse>

               </el-collapse-item>



               <el-collapse-item name="3" style=" align-content: center; text-align: center; display: block; align-items: center ; align-self: center; margin-bottom:2px;" >
                <template #title >Product and Service  </template>
               
                
               <el-collapse v-model="activeName2"   accordion  style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;" >
                  

                <el-collapse-item name="3-1" style="margin-bottom:2px;">
                      <template #title >Sheet Pile  </template>
                      <br/>
                          <div  style="text-align:left;align-content: left; display: block;">
                            Offer stability and preventing soil erosion. They create retaining walls and barriers, ensuring the integrity of the reclaimed land. 
                            <br/>
                              Steel sheet piles also contribute to the construction of bulkheads and seawalls, protecting against wave action. 
                          </div>
                </el-collapse-item>
                      
                <el-collapse-item name="3-2" style="margin-bottom:2px;">
                      <template #title >H-Beam (Soldier Pile)  </template>
                      <br/>
                          <div  style="text-align:left;align-content: left; display: block;">
                            H-beams, also known as soldier piles, are strong steel beams with an 'H' shape.
                            <br/><br/>
                             They are used in construction to provide support for retaining walls and deep excavations. These beams help prevent the collapse of soil and ensure the stability and safety of construction sites. </div>
                </el-collapse-item>
                
                   </el-collapse>

               </el-collapse-item>

            </el-collapse>

      </div>  



      <div v-if="selectedmenus == 'construction'">        
        
        <el-collapse v-model="activeName" accordion  >
              
              <el-collapse-item name="1" style="margin-bottom:2px;">
                <template #title >  Description </template>
               
                <div  style="text-align:left;align-content: left; display: block;">
                  Oriental Shoring Solution (OSS) is a specialized underground foundation expert, 
                  offering a comprehensive range of services in temporary excavation and 
                  earth-retaining stabilizing structure (ERSS) construction.
                  <br/><br/>
                  As a one-stopsolution provider, OSS is dedicated to meeting the needs of 
                   projects requiring expertise in creating temporary openings
                   in the ground for construction purposes. OSP work closely 
                   with OSS collaborating to deliver integrated solutions.
                 
                </div>
              </el-collapse-item>

      
             
              <el-collapse-item name="3" style="margin-bottom:2px;">
                <template #title >  Address </template>
                <el-collapse v-model="activeName2" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;"  >
                    
                  
                  <el-collapse-item name="3-1">
                       <template #title > Malaysia   </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        <br/>Block I, Unit 11, Dataran Prima,
                        <br/> Jalan PJU 1/37, 
                        <br/>47301, Petaling Jaya, Selangor, Malaysia
                        
                        
                      </div>
      
                   </el-collapse-item>
      
                  
                   <el-collapse-item name="3-2">
                       <template #title >Singapore  </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                       <br/> 18 Howard Road,
                       <br/>#10-04 Novelty Bizcenter, 
                       <br/>369585 Singapore 
                                                
                       
                      </div>
      
                   </el-collapse-item>


                   <el-collapse-item name="3-3">
                       <template #title >Indonesia   </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                       <br/>Ruko De Mansion, Jl. Jalur Sutra,
                       <br/>Kelurahan Kuciran Jaya, Pinang, 
                       <br/>Tangerang, Banten 15144, Indonesia
                                                
                       
                      </div>
      
                   </el-collapse-item>

      
                </el-collapse>
            
              </el-collapse-item>
      
     
            </el-collapse>


      </div>  







      <div v-if="selectedmenus == 'powerstation'">        
       

        <el-collapse v-model="activeName" accordion  >
              
              <el-collapse-item name="1" style="margin-bottom:2px;">
                <template #title >  Description </template>
               
                <div  style="text-align:left;align-content: left; display: block;">
                  To create strong foundations, serve as cofferdams to keep water out, and act as retaining walls for slopes and erosion control.
                  <br/><br/>
                  We can help in maintaining dry work areas, offer cost-effective solutions, and are eco-friendly, making them a valuable choice in power plant construction.
                 
                </div>
              </el-collapse-item>

      
             
              <el-collapse-item name="3" style="margin-bottom:2px;">
                <template #title >  Common Solution  </template>
                <el-collapse v-model="activeName2" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;"  >
                    
                  
                  <el-collapse-item name="3-1">
                       <template #title >Temporary Cofferdam    </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        To provides a reliable, efficient, and cost-effective solution for creating a dry work area, facilitating construction activities below the water table while ensuring worker safety and minimizing project delays.
                        
                      </div>
      
                   </el-collapse-item>
      
                  
                   <el-collapse-item name="3-2">
                       <template #title >Permanent sheet pile  </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        Providing stable foundations for structures, constructing waterfront facilities like intake and discharge structures, and serving as noise barriers to minimize sound pollution in urban areas. 
                        <br/><br/>
                        Their durability and longevity ensure the continued operation and safety of power plants while addressing various construction and environmental needs.
                       
                      </div>
      
                   </el-collapse-item>
      
                </el-collapse>
            
              </el-collapse-item>
      
              
              <el-collapse-item name="4" style="margin-bottom:2px;">
                <template #title > Product and Service  </template>
                <el-collapse v-model="activeName2" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;" >
                    
                  
                  <el-collapse-item name="4-1">
                       <template #title > Steel Sheet Pile </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        Steel sheet piles are commonly used in the construction of cofferdams, which allow for the dewatering of construction areas and the creation of a dry working environment. 
                        <br/><br/>
                      Additionally, they can be employed in the construction of seawalls and retaining walls to protect against water intrusion and maintain the integrity of the power plant infrastructure
                       </div>
      
                   </el-collapse-item>
      
                  
                   <el-collapse-item name="4-2">
                       <template #title > H-Beam (Soldier Pile)   </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        H-beams, also known as soldier piles, are strong steel beams with an 'H' shape. They are used in construction to provide support for retaining walls and deep excavations.
                        <br/><br/>
                        These beams help prevent the collapse of soil and ensure the stability and safety of construction sites.
                        
                      
                      </div>
      
                   </el-collapse-item>
      
                </el-collapse>
    
              </el-collapse-item>
      
            </el-collapse>


      </div>  








      <div v-if="selectedmenus == 'house'">        
     
        
        <el-collapse v-model="activeName" accordion  >
              
       
             
              <el-collapse-item name="1" style="margin-bottom:2px;">
                <template #title >  House </template>
                <el-collapse v-model="activeName2" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;"  >
                    
                  
                  <el-collapse-item name="1-1">
                       <template #title > Oriental  Housetop   </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        We offer a diverse range of products, encompassing architectural roof systems, landscape solutions, and engineered fiber solutions.
                         Our goal is to help you create homes that enhance your lifestyle, prioritizing both comfort and convenience.
                                            
                      </div>
      
                   </el-collapse-item>
      
                  
                   <el-collapse-item name="1-2">
                       <template #title > Pitch Roof   </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        Lightweight nature, durability, and fire resistance, are a popular choice for residential roofing. 
                        <br/><br/>
                        We offer reliable protection against various weather conditions and are easy to install.
                        Available in a wide range of colors and styles, they provide an affordable option for homeowners seeking a durable and aesthetically pleasing roofing solution for their houses
                        
                       
                      </div>
      
                   </el-collapse-item>


                   <el-collapse-item name="1-3">
                       <template #title > Low Slope Roof </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        To offer a sleek aesthetic and can contribute to energy efficiency with proper insulation. 
                        
                       
                      </div>
      
                   </el-collapse-item>


                   <el-collapse-item name="1-4">
                       <template #title > Wood Plastic Composite </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        To Provide  advantages such as durability, moisture resistance, dimensional stability, eco-friendliness, and versatility. 
                        <br/><br/>
                        WPC is commonly used as an alternative to traditional wood in applications like decking, fencing, and outdoor furniture
                        
                       
                      </div>
      
                   </el-collapse-item>
      
                </el-collapse>
            
              </el-collapse-item>
      
     
            </el-collapse>



      </div>  








      <div v-if="selectedmenus == 'trainstation'">        
      
        
        <el-collapse v-model="activeName" accordion  >
              
              <el-collapse-item name="1" style="margin-bottom:2px;">
                <template #title >  Description </template>
               
                <div  style="text-align:left;align-content: left; display: block;">
                  In train station projects, steel sheet piles are like strong building blocks. 
                  <br/><br/>
                  They create steady foundations for train station structures and keep water out of construction areas when needed, making it safe to build. 
                 
                </div>
              </el-collapse-item>

      
             
              <el-collapse-item name="3" style="margin-bottom:2px;">
                <template #title >  Common Solution  </template>
                <el-collapse v-model="activeName2" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;"  >
                    
                  
                  <el-collapse-item name="3-1">
                       <template #title >Temporary cofferdam   </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        To provides a reliable, efficient, and cost-effective solution for creating a dry work area, facilitating construction activities below the water table while ensuring worker safety and minimizing project delays
                        
                        
                        
                      </div>
      
                   </el-collapse-item>
                       
    
      
                </el-collapse>
            
              </el-collapse-item>
      
              
              <el-collapse-item name="4" style="margin-bottom:2px;">
                <template #title > Product and Service  </template>
                <el-collapse v-model="activeName2" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;" >
                    
                  
                  <el-collapse-item name="4-1">
                       <template #title > Steel Sheet Pile </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        These piles effectively control water ingress, ensuring a dry and safe work environment, particularly in areas with high groundwater levels. 
                        <br/><br/>
                        Their stability prevents soil or water collapse during excavation, reducing project risks and delays
                       </div>
      
                   </el-collapse-item>
      
                  
                   <el-collapse-item name="4-2">
                       <template #title > Pipe Struts    </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        Serve as structural supports, providing strength and stability to train car frameworks, undercarriages, and chassis.
                      
                       
                      </div>
      
                   </el-collapse-item>
      
    
                   <el-collapse-item name="4-3">
                       <template #title >  Beam   </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        Providing crucial structural support and stability. They are utilized in various aspects of train construction, including the framework of train cars, bridges, and elevated sections of tracks.
                      </div>
      
                   </el-collapse-item>                   

                </el-collapse>
    
              </el-collapse-item>
     
            </el-collapse>


      </div>  

     
      




      <div v-if="selectedmenus == 'hospital'">        
          <el-collapse v-model="activeName"  accordion >
         
            <el-collapse-item name="1" style="margin-bottom:2px;">
                <template #title >Description  </template>
                <br/>
                <div  style="text-align:left;align-content: left; display: block;">
                  We serve as a fundamental structural element in hospital development, contributing to excavation support, waterproofing, foundation stability,
                   and space optimization.
                   <br/> <br/>
                   Our robust and versatile foundation solutions provide the necessary structural integrity to create secure hospital facilities, 
                   <br/>ensuring the safety of patients and healthcare professionals while promoting the long-term viability of the healthcare infrastructure
                </div>
                </el-collapse-item>
               
               
                <el-collapse-item name="2" style="margin-bottom:2px;">
                <template #title >Common Solution   </template>
               
                <b>Deep Excavation </b>
                <br/>
                <div  style="text-align:left;align-content: left; display: block;">
                  Offer crucial support and stability to the surrounding soil, preventing potential collapse or soil movement.
                  <br/>  <br/>
                   By driving the steel sheet piles into the ground, they act as retaining walls, creating a secure boundary
                   <br/>
                    for the basement area. This ensures the structural integrity of the mall basement, allowing for safe and 
                    <br/>
                    functional usage of the space.
                </div>
                </el-collapse-item>
               
            


                <el-collapse-item name="4" style=" align-content: center; text-align: center; display: block; align-items: center ; align-self: center; margin-bottom:2px;" >
                <template #title >Product and Service  </template>
               
                
               <el-collapse v-model="activeName2"   accordion  style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;" >
                  

                <el-collapse-item name="4-1" style="margin-bottom:2px;">
                      <template #title >Sheet Pile </template>
                      <br/>
                          <div  style="text-align:left;align-content: left; display: block;">
                            Offer stability and preventing soil erosion. They create retaining walls and barriers,
                            <br/>
                             ensuring the integrity of the reclaimed land. 
                             <br/>
                          Steel sheet piles also contribute to the construction of bulkheads and seawalls, 
                          <br/>
                          protecting against wave action. 
                          </div>
                </el-collapse-item>
                      
              
                <el-collapse-item name="4-2" style="margin-bottom:2px;">
                      <template #title >Pipe Struts </template>
                      <br/>
                          <div  style="text-align:left;align-content: left; display: block;">
                            To ensure additional support and stability to steel sheet piles, reinforcing 
                            <br/>
                            their structural integrity and enhancing their effectiveness in retaining soil
                            <br/>
                             and preventing ground movement. This combined system of steel struts and 
                             <br/>
                             sheet piles ensures a secure foundation for the hospital, promoting the
                             <br/>
                              safety and long-term stability of the facility.
                            </div>

                </el-collapse-item>        


                <el-collapse-item name="4-3" style="margin-bottom:2px;">
                      <template #title >Tie Rod </template>
                      <br/>
                          <div  style="text-align:left;align-content: left; display: block;">
                            To bolster and reinforce the sheet piles, playing a crucial role in maintaining
                            <br/>
                             their alignment, stability, and structural integrity. By effectively preventing
                             <br/>
                              lateral movement and preserving the desired spacing and alignment, tie rods
                              <br/>
                               ensure a robust foundation for the hospital, contributing significantly 
                               <br/>
                               to the overall safety and stability of the structure.

                              </div>

                </el-collapse-item>    



                <el-collapse-item name="4-4" style="margin-bottom:2px;">
                      <template #title >Beam  </template>
                      <br/>
                          <div  style="text-align:left;align-content: left; display: block;">
                            Commonly used in structural frameworks, support systems, and equipment installations. 
                            <br/>
                        They provide load-bearing capacity and structural
                        <br/>
                        integrity to the power plant's buildings, including 
                        <br/>
                        turbine halls, boiler structures, and control rooms.

                      </div>

                </el-collapse-item>  


                <el-collapse-item name="4-5" style="margin-bottom:2px;">
                      <template #title >Hydro Press-in Piling (Silent Piling)  </template>
                      <br/>
                          <div  style="text-align:left;align-content: left; display: block;">
                            To provide a sophisticated and minimally disruptive solution for installing 
                            <br/>
                            piles in construction projects, we propose the use of Hydro Press-in Piling
                            <br/>
                             (silent piling) for sheet piling installation. This method effectively 
                             <br/>
                             reduces the environmental impact associated with construction activities,
                             <br/>
                              reflecting a sustainable and considerate approach to urban development
                              <br/>
                               and infrastructure projects.

                              </div>

                </el-collapse-item>  

                   </el-collapse>

               </el-collapse-item>

              </el-collapse>

      </div>  


      <div v-if="selectedmenus == 'port'">      

        <el-collapse v-model="activeName" accordion  >
              
              <el-collapse-item name="1" style="margin-bottom:2px;">
                <template #title >  Description </template>
               
                <div  style="text-align:left;align-content: left; display: block;">
                  In the port project, we helped by providing smart solutions to keep the soil in place and prevent erosion in the marine environment. 
                  <br/><br/>
                  Our contribution ensured the stability of the port infrastructure, protecting it from erosion and tidal forces, and making it a durable and efficient facility for handling cargo and vessels.
      
                </div>
              </el-collapse-item>


              <el-collapse-item name="2" style="margin-bottom:2px;">
                <template #title > Permanent work  </template>
               
                <div  style="text-align:left;align-content: left; display: block;">
                  we focused on creating long-lasting solutions. Our efforts were dedicated to building infrastructure that stands the test of time, meeting the needs of today and tomorrow. 
                </div>
              </el-collapse-item>

      
             
              <el-collapse-item name="3" style="margin-bottom:2px;">
                <template #title >  Common Solution  </template>
                <el-collapse v-model="activeName2" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;"  >
                    
                  
                  <el-collapse-item name="3-1">
                       <template #title > Double Walls  </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        Double-walled retaining walls, comprised of two parallel walls with a space in between.
                        <br/><br/>
                        They effectively control erosion along waterfronts, provide robust load-bearing support for heavy cargo and structures, offer protection against wave action, optimize space utilization, ensure foundation stability, and can be tailored to specific project needs
                      </div>
      
                   </el-collapse-item>
      
                  
                   <el-collapse-item name="3-2">
                       <template #title >  Combi Walls  </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        Construction technique that combines different types of retaining or foundation walls to create a comprehensive and versatile solution.
                        <br/><br/>
                        This combination often involves using steel sheet piles along with other materials
                      </div>
      
                   </el-collapse-item>
      
                </el-collapse>
            
              </el-collapse-item>
      
              
              <el-collapse-item name="4" style="margin-bottom:2px;">
                <template #title > Product and Service  </template>
                <el-collapse v-model="activeName2" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;" >
                    
                  
                  <el-collapse-item name="4-1">
                       <template #title > Steel Sheet Pile </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        Effective solution for constructing seawalls, quay walls, bulkheads, and other marine structures.
                        <br/><br/>
                        Steel sheet piles provide stability and support in challenging environments, withstanding the forces of water and providing erosion control.
                         They are driven vertically into the ground to create a barrier, preventing soil erosion and maintaining the integrity of the port infrastructure.
                       </div>
      
                   </el-collapse-item>
      
                  
                   <el-collapse-item name="4-2">
                       <template #title >  Pipe Steel Pile   </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        Offer foundational support and stability. They are driven vertically into the ground,
                      typically through the water or soil, to create load-bearing columns.
                      </div>
      
                   </el-collapse-item>
      
    
                   <el-collapse-item name="4-3">
                       <template #title >  Tie rod  </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        Provide a solid surface for the movement and parking of heavy equipment, vehicles, and containers within the port area. 
                        Tie rod are designed to withstand heavy loads and frequent traffic, ensuring efficient logistics and cargo handling operations
                      </div>
      
                   </el-collapse-item>                   

                </el-collapse>
      
              </el-collapse-item>
      
            </el-collapse>

      </div>  













      

      <div v-if="selectedmenus == 'ospfactory'">     

           <el-collapse v-model="activeName" accordion  >
              
                    <el-collapse-item name="1" style="margin-bottom:2px;">
                      <template #title >  What we do? </template>
                    
                      <div  style="text-align:left;align-content: left; display: block;">
                        Oriental Steel Pipes produces a diverse range of steel pipes and delivers them directly to construction sites.
                        <br/><br/>
                         We offer comprehensive guidance and support to assist clients, engineers, and contractors in selecting the appropriate steel pipes and ensuring their effective utilization.
                          In order to provide an even stronger foundation solution for specific projects, OSPipe works closely with Oriental Sheet Piling, collaborating to deliver integrated solutions.
                      
                      </div>
                    </el-collapse-item>

      
                        <el-collapse-item name="2" style="margin-bottom:2px;">
                          <template #title > Address:   </template>
                          <el-collapse v-model="activeName2" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;"  >
                              
                            
                            <el-collapse-item name="2-1">
                                <template #title >Pulau Indah  </template>
                                
                                <div  style="text-align:left;align-content: left; display: block;">
                                  Lebuh Sungai Chandong, Pulau Indah Industrial Park, 42000 Pulau Indah, Selangor 
                                  
                                  
                                  
                                </div>
                
                            </el-collapse-item>
                                
                            <el-collapse-item name="2-2">
                                <template #title >Contact number : </template>
                                
                                <div  style="text-align:left;align-content: left; display: block;">
                                  03-31023423
                                  
                                </div>
                
                            </el-collapse-item>
                
                          </el-collapse>
            
              </el-collapse-item>
      
      
        </el-collapse> 

      </div>  







      <div v-if="selectedmenus == 'cardealership'">     

          <el-collapse v-model="activeName" accordion  >
            
                  <el-collapse-item name="1" style="margin-bottom:2px;">
                    <template #title >  Description </template>
                  
                    <div  style="text-align:left;align-content: left; display: block;">
                      Discover the extensive range of Lexus vehicles available in Malaysia and take the latest SUVs, sedans, 
                      and coupes for a test drive. Our dealerships are located in Selangor and Perak, Malaysia
                    </div>
                  </el-collapse-item>


                      <el-collapse-item name="2" style="margin-bottom:2px;">
                        <template #title > Address:   </template>
                        <el-collapse v-model="activeName2" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;"  >
                            
                          
                          <el-collapse-item name="2-1">
                              <template #title >Perak   </template>
                              <img  src="/picture/volkswagen_ipoh.png"  style="margin-bottom: -30px;  width:70%; height:70%;" /> 
                              <br/> <br/> 
                              <div  style="text-align:left;align-content: left; display: block;">
                                Lot 8105N, Jalan Marmar Off Jalan Kuala Kangsar Ipoh, 30010 Perak
                                
                                
                                
                              </div>
              
                          </el-collapse-item>
                              
                          <el-collapse-item name="2-2">
                              <template #title >Penang</template>
                              <img  src="/picture/volkswagen_penang.png"  style="margin-bottom: -30px;  width:70%; height:70%;" /> 
                              <br/> <br/>
                              <div  style="text-align:left;align-content: left; display: block;">
                                PMT 1874 Lorong, Taman, Jalan Iks Bukit Tengah, 14000 Bukit Mertajam, Penang
                                
                              </div>
              
                          </el-collapse-item>
              
                          <el-collapse-item name="2-3">
                              <template #title >Selangor</template>
                              <img  src="/picture/audi_selangor.png"  style="margin-bottom: -30px;  width:70%; height:70%;" /> 
                              <br/> <br/> 
                              <div  style="text-align:left;align-content: left; display: block;">
                                U13, 22 A Persiaran Setia Dagang Seksyen, Setia Alam, 40170 Shah Alam, Selangor
                                
                              </div>
              
                          </el-collapse-item>


                        </el-collapse>
          
            </el-collapse-item>


          </el-collapse> 

      </div>        









      <div v-if="selectedmenus == 'gasketalley'">        
          <el-collapse v-model="activeName" accordion  >
          
              
              <el-collapse-item name="1">
                <template #title >  Description </template>
                <div  style="text-align:left;align-content: left; display: block;">
                  
                  Gasket Alley is a premier destination for automotive enthusiasts in Malaysia and the region, boasting a one-of-a-kind, vibrant atmosphere that sets it apart from the rest
                </div>
              </el-collapse-item>

                  
              <el-collapse-item name="2">
                <template #title >  Address: </template>
                <div  style="text-align:left;align-content: left; display: block;">
                  
                  Lot 15, 13, Jln 13/6, Seksyen 13, 46200 Petaling Jaya, Selangor
                  
                </div>
              </el-collapse-item>

            </el-collapse>

      </div>  

      <div v-if="selectedmenus == 'orbit'">        
          <el-collapse v-model="activeName" accordion  >
         
         
                <br/> 
            
              <el-collapse-item name="1">

                <template #title >  Partnering </template>
                <br/> 
                <div  style="text-align:left;align-content: left; display: block;">
                  A long-standing partnership between ArcelorMittal and Oriental Sheet Piling (OSP) has yielded remarkable results.
                  <br/><br/> 
                  OSP gained invaluable access to ArcelorMittal's global network, expertise, and reach, propelling its growth and success. Today, as a thriving business unit within ArcelorMittal, they stand united in developing and delivering innovative sheet pile solutions for diverse construction projects around the world.
                </div>
              </el-collapse-item>

              <el-collapse-item name="2">

            <template #title >  The ArcelorMittal Orbit </template>
            <br/> 
            <div  style="text-align:left;align-content: left; display: block;">
              The ArcelorMittal Orbit is a prominent landmark located in Queen Elizabeth Olympic Park in London, United Kingdom. 
              Designed by Anish Kapoor and Cecil Balmond. 
              <br/><br/>
              The ArcelorMittal Orbit is a unique structure made primarily of steel, featuring an intricate lattice design that spirals upwards. 
              It stands at a height of 114.5 meters (376 feet) and offers panoramic views of London from its observation deck.
            
              <br/><br/>
              The structure is a testament to the innovative use of steel in architecture and showcases the possibilities of combining art 
              and engineering in a remarkable way  

              </div> 
            </el-collapse-item>

            </el-collapse>

      </div>  
      


    

      <div v-if="selectedmenus == 'landreclaim'">        
          <el-collapse v-model="activeName" accordion  >
                    
              <el-collapse-item name="1">
                <template #title >  Description </template>
                <div  style="text-align:left;align-content: left; display: block;">
                  We played a crucial role in helping others create new land areas along coastlines and bodies of water. We brought our expertise to the table to create solutions, supplying and installing the foundational structures needed for the project's success. With support, we expanded the available land for various purposes, such as urban development, infrastructure projects, and coastal protection.
                </div>
              </el-collapse-item>


              <el-collapse-item name="2">
                <template #title >  Common Solution </template>
                <el-collapse v-model="activeName2"   accordion  style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;" >
                  

                  <el-collapse-item name="2-1" style="margin-bottom:2px;">
                        <template #title >Permanent work  </template>
                        <br/>
                            <div  style="text-align:left;align-content: left; display: block;">
                              We focused on creating long-lasting solutions. Our efforts were dedicated to building infrastructure that stands the test of time, meeting the needs of today and tomorrow. 
                            </div>
                  </el-collapse-item>
                        
                  <el-collapse-item name="2-2" style="margin-bottom:2px;">
                        <template #title >Combi Walls   </template>
                        <br/>
                            <div  style="text-align:left;align-content: left; display: block;">
                              Combi walls are made by combining different types of sheet piles to create strong and flexible walls that can be used for many different projects, such as retaining walls, flood protection, and marine structures.
                              <br/><br/>
                               They are cost-effective because they are easy to install, have corrosion-resistant coatings, and can be adapted to different project needs
                            </div>
                  </el-collapse-item>
                  
                     </el-collapse>
              </el-collapse-item>
                  
             
              <el-collapse-item name="3">
                <template #title >  Product and Service  </template>
                <el-collapse v-model="activeName2"   accordion  style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;" >
                  

                  <el-collapse-item name="3-1" style="margin-bottom:2px;">
                        <template #title >Steel Sheet Pile </template>
                        <br/>
                            <div  style="text-align:left;align-content: left; display: block;">
                              Offer stability and preventing soil erosion. They create retaining walls and barriers, ensuring the integrity of the reclaimed land. 
                              <br/><br/>
                              Steel sheet piles also contribute to the construction of bulkheads and seawalls, protecting against wave action. 
                              </div>
                  </el-collapse-item>
                        
                  <el-collapse-item name="3-2" style="margin-bottom:2px;">
                        <template #title >Steel Pipe    </template>
                        <br/>
                            <div  style="text-align:left;align-content: left; display: block;">
                              Offer foundational support and stability. They are driven vertically into the ground,
                            typically through the water or soil, to create load-bearing columns.
                            </div>
                  </el-collapse-item>
                  
                  <el-collapse-item name="3-3" style="margin-bottom:2px;">
                        <template #title >Straight Web Steel Sheet Pile    </template>
                        <br/>
                            <div  style="text-align:left;align-content: left; display: block;">
                              Straight web steel sheet piles are strong and flexible construction materials made of individual steel sheets with a straight web design.
                              <br/><br/>
                              They are combined to create sturdy walls for different projects . These sheet piles are cost-effective, easy to install, and have corrosion-resistant coatings. 
                              They can adapt to various project needs and provide reliable load-bearing capacity.
                            </div>
                  </el-collapse-item>
                  

                  <el-collapse-item name="3-4" style="margin-bottom:2px;">
                        <template #title >Beam    </template>
                        <br/>
                            <div  style="text-align:left;align-content: left; display: block;">
                              Provide the structural framework and stability needed to protect reclaimed land from erosion and the forces of the sea. 
                              Beams serve as the backbone of revetments, shorelines structures such as breakwaters and seawalls. 
                            </div>
                  </el-collapse-item>
                  

                  <el-collapse-item name="3-5" style="margin-bottom:2px;">
                        <template #title >Tie Rod   </template>
                        <br/>
                            <div  style="text-align:left;align-content: left; display: block;">
                              To Give access to the reclaimed land, allowing for the movement of heavy machinery, vehicles, and personnel.
                              <br/><br/>
                              Tie roads ensure efficient logistics and support the transportation of construction materials, equipment, and resources to and from the reclaimed area.
                            </div>
                  </el-collapse-item>

                     </el-collapse>
              </el-collapse-item>

            </el-collapse>

      </div>  

  


      <div v-if="selectedmenus == 'petrotankplant'">        
          <el-collapse v-model="activeName" accordion  >
                    
              <el-collapse-item name="1">
                <template #title >  Description </template>
             
                <div  style="text-align:left;align-content: left; display: block;">
                  Oriental Sheet Piling have played a crucial role in assisting the establishment
                   of petrol tank plants through land reclamation efforts.
                   <br/><br/>
                   Leveraging our expertise,  we provide comprehensive solutions for reclaiming land 
                   <br/>
                   and creating the necessary  infrastructure for these plants. 
                   With our support,  we expand the available land areas,  enabling the construction and operation 
                
                    of petrol tank plants to meet the growing demand.
                </div>
              </el-collapse-item>

            </el-collapse>

      </div>  




    
<div v-if="selectedmenus == 'river'">        
    <el-collapse v-model="activeName" accordion  >
              
        <el-collapse-item name="1" style="margin-bottom:2px;">
          <template #title >  Description </template>
         
          <div  style="text-align:left;align-content: left; display: block;">
            Our foremost goal was to establish a robust and comprehensive system to shield communities and infrastructure from the devastating impacts of flooding.
            <br/><br/>
            We employed a combination of advanced engineering solutions, such as levees, flood barriers, and natural water management features.

          </div>
        </el-collapse-item>

       
        <el-collapse-item name="2" style="margin-bottom:2px;">
          <template #title >  Common Solution  </template>
          <el-collapse v-model="activeName2" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;"  >
              
            
            <el-collapse-item name="2-1">
                 <template #title >  Flood Control  </template>
                
                <div  style="text-align:left;align-content: left; display: block;">
                  We utilized steel sheet piles to build robust flood barriers and retaining walls, protecting communities and infrastructure from flooding. 
                  <br/><br/>
                  Our priority was to establish a flood control system that provides immediate protection and long-term resilience, ensuring the safety and well-being of those in flood-prone areas.

                </div>

             </el-collapse-item>

            
             <el-collapse-item name="2-2">
                 <template #title >  Bank Protection   </template>
                
                <div  style="text-align:left;align-content: left; display: block;">
                  We used steel sheet piles to build strong bank protection systems, protecting riverbanks and shorelines from erosion.
                  <br/><br/>
                  Our main goal was to create a stable and long-lasting solution that safeguards the environment and important infrastructure.
                </div>

             </el-collapse-item>

             <el-collapse-item name="2-3">
                 <template #title > Combi Walls   </template>
                
                <div  style="text-align:left;align-content: left; display: block;">
                  Combi walls are made by combining different types of sheet piles to create strong and flexible walls that can be used for many
                   different projects, such as retaining walls, flood protection, and marine structures. 
                   <br/><br/>
                  They are cost-effective because they are easy to install, have corrosion-resistant coatings, and can be adapted to different project needs
                </div>

             </el-collapse-item>


             <el-collapse-item name="2-4">
                 <template #title >Box walls  </template>
                
                <div  style="text-align:left;align-content: left; display: block;">
                  Box piles are frequently chosen when projects demand high load-bearing capacity and stiffness, like supporting heavy structures,
                   retaining deep excavations, or creating watertight marine bulkheads.
                  <br/><br/>
                  They excel in strength and stability due to their closed-box design, making them ideal for tough construction situations.
                </div>

             </el-collapse-item>

          </el-collapse>

        </el-collapse-item>

        
        <el-collapse-item name="3" style="margin-bottom:2px;">
          <template #title > Product and Service  </template>
          <el-collapse v-model="activeName2" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;" >
              
            
            <el-collapse-item name="3-1">
                 <template #title > Steel Sheet Pile </template>
                
                <div  style="text-align:left;align-content: left; display: block;">
                  Steel sheet piles are commonly used in river projects for erosion control, cofferdams, and constructing river walls. 
                  <br/><br/>
                  They prevent soil loss, create dry work environments, and offer stability. Steel sheet piles are durable and versatile, making them a reliable choice for river projects
                </div>

             </el-collapse-item>

            
             <el-collapse-item name="3-2">
                 <template #title >  Tie Back (Strutting/ Anchored)   </template>
                
                <div  style="text-align:left;align-content: left; display: block;">
                  Provide a solid surface for the movement and parking of heavy equipment, vehicles, and containers within the port area. 
Tie roads are designed to withstand heavy loads and frequent traffic, ensuring efficient logistics and cargo handling operations
                </div>

             </el-collapse-item>

          </el-collapse>

        </el-collapse-item>

      </el-collapse>

</div>  







  
<div v-if="selectedmenus == 'jetty'">        
    <el-collapse v-model="activeName" accordion  >
              
        <el-collapse-item name="1" style="margin-bottom:2px;">
          <template #title >  Description </template>
         
          <div  style="text-align:left;align-content: left; display: block;">
            Participated in the Jetty project, offering effective solutions for soil retention and erosion prevention in marine environments

          </div>
        </el-collapse-item>

       
        <el-collapse-item name="2" style="margin-bottom:2px;">
          <template #title >  Common Solution  </template>
          <el-collapse v-model="activeName2" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;"  >
              
            
            <el-collapse-item name="2-1">
                 <template #title > Double Walls   </template>
                
                <div  style="text-align:left;align-content: left; display: block;">
                              
                  Double-walled retaining walls, comprised of two parallel walls with a space in between.
                  <br/><br/>
                  They effectively control erosion along waterfronts, provide robust load-bearing support for heavy cargo 
                  and structures, offer protection against wave action,
                  optimize space utilization, ensure foundation stability, and can be tailored to specific project needs

                </div>

             </el-collapse-item>

            

             <el-collapse-item name="2-2">
                 <template #title > Combi Walls   </template>
                
                <div  style="text-align:left;align-content: left; display: block;">
                              
                 
                 
                  Construction technique that combines different types of retaining or foundation walls to create a comprehensive and versatile solution. 
                  <br/><br/>
                  This combination often involves using steel sheet piles along with other materials

                </div>

             </el-collapse-item>

          </el-collapse>


        </el-collapse-item>

        
        <el-collapse-item name="3" style="margin-bottom:2px;">
          <template #title > Product and Service  </template>
          <el-collapse v-model="activeName2" accordion style="max-width: 80%;  align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;" >
              
            
            <el-collapse-item name="3-1">
                 <template #title > Steel Sheet Pile </template>
                
                <div  style="text-align:left;align-content: left; display: block;">
                 
                  
                  Act as retaining walls, preventing soil erosion and maintaining the integrity of the jetty.
                 <br/><br/>
                  Sheet piles also provide a barrier against wave action, ensuring the stability of the Jetty structure. 
                  Additionally, they serve as foundation elements, providing a solid base for the construction. 
                  Sheet piles are essential for creating resilient and stable jetties in marine environments.
                </div>

             </el-collapse-item>

            
             <el-collapse-item name="3-2">
                 <template #title > Pipe Steel Pile   </template>
                
                <div  style="text-align:left;align-content: left; display: block;">
                  To offer a robust and versatile solution in construction and engineering projects. 
                  <br/><br/>
                  Steel pipe strength, corrosion resistance, and load-bearing capacity make them ideal for 
                  foundation support, retaining walls, and waterfront structures, while their adaptability to
                   different soil conditions ensures versatility in design
                </div>

             </el-collapse-item>


             <el-collapse-item name="3-3">
                 <template #title >Beam    </template>
                
                <div  style="text-align:left;align-content: left; display: block;">
                
                  H beams, often used in construction as structural support elements, can also be incorporated into combined walls or combi walls. 
                  <br/><br/>
                  When utilized in this manner, H beams are integrated with other materials, to create a comprehensive and versatile retaining or foundation structure. 
                </div>

             </el-collapse-item>


             <el-collapse-item name="3-4">
                 <template #title >Tie Rod</template>
                
                <div  style="text-align:left;align-content: left; display: block;">
                                                    
                  Typically employed in the bracing system of the structure. 
                  <br/><br/>
                  They help to counteract the lateral loads induced by water movements, wave action, and vessel impacts.
                </div>

             </el-collapse-item>

          </el-collapse>

        </el-collapse-item>

      </el-collapse>

</div>  









      <div v-if="selectedmenus == 'bridge'">        
     
        
        <el-collapse v-model="activeName" accordion  >
              
              <el-collapse-item name="1" style="margin-bottom:2px;">
                <template #title >  Description </template>
               
                <div  style="text-align:left;align-content: left; display: block;">
                  Our goal was to construct a durable and long-lasting bridge that provides a safe and efficient passage for people and vehicles.
                  <br/><br/>
                  We focused on enhancing connectivity and ensuring the bridge's reliability for years to come, dedicating our efforts to building a lasting infrastructure.
                  </div>
              </el-collapse-item>
      
             
              <el-collapse-item name="2" style="margin-bottom:2px;">
                <template #title >  Common Solution  </template>
                <el-collapse v-model="activeName2" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;"  >
                    
                  
                  <el-collapse-item name="2-1">
                       <template #title >  Bridge Abutment   </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        Steel sheet piles offer an accelerated bridge construction method that minimizes disruption to the network by providing rapid and reliable bridge abutment support. 
                        <br/><br/>
                        Bridge abutments must withstand significant vertical forces from the bridge superstructure and act as retaining walls for the soil that supports the approach to the bridge.
                      </div>
      
                   </el-collapse-item>
      
                  
                   <el-collapse-item name="2-2">
                       <template #title >Permanent work   </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        we focused on creating long-lasting solutions. Our efforts were dedicated to building infrastructure that stands the test of time, meeting the needs of today and tomorrow. 
                      </div>
      
                   </el-collapse-item>
      
                   <el-collapse-item name="2-3">
                       <template #title > Temporary Cofferdam   </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        To provides a reliable, efficient, and cost-effective solution for creating a dry work area, facilitating construction activities below the water table while ensuring worker safety and minimizing project delays
                      </div>
      
                   </el-collapse-item>
      
           
                   
                </el-collapse>
      
      
              </el-collapse-item>
      
              
              <el-collapse-item name="3" style="margin-bottom:2px;">
                <template #title > Product and Service  </template>
                <el-collapse v-model="activeName2" accordion style="max-width: 80%; align-content: center; text-align: center; margin-left:auto; margin-right:auto; margin-bottom:2px; margin-top:2px;" >
                    
                  
                  <el-collapse-item name="3-1">
                       <template #title > Steel Sheet Pile </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        Steel sheet piles are essential in bridge construction, providing support and stability. 
                        <br/><br/>
                        They are used in foundation applications, retaining walls, and cofferdams. 
                        Steel sheet piles prevent soil erosion, create a dry work environment, and offer stability for bridge piers.
                    </div>
      
                   </el-collapse-item>
      
                  
                   <el-collapse-item name="3-2">
                       <template #title >  Beam   </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        Serve multiple functions, including supporting the bridge deck, spanning distances between
                        piers or abutments, distributing loads, and ensuring structural stability. 
                        <br/><br/>
                        Beams play a crucial role in transferring weight and forces to the bridge's foundation, allowing safe passage for vehicles and pedestrians.
                      </div>
      
                   </el-collapse-item>
      
                   <el-collapse-item name="3-3">
                       <template #title >  Tie Rod   </template>
                      
                      <div  style="text-align:left;align-content: left; display: block;">
                        To bolster and reinforce the sheet piles, playing a crucial role in maintaining their alignment, stability, and structural integrity.
                        <br/><br/>
                         By effectively preventing lateral movement and preserving the desired spacing and alignment, tie rods ensure a robust foundation for the hospital, contributing significantly to the overall safety and stability of the structure.
                      </div>
      
                   </el-collapse-item>           
                   



                </el-collapse>
      
                
              </el-collapse-item>
      
      
      
            </el-collapse>


      </div>  







            </el-col>
           
            </el-row>
          
          
          
          </el-scrollbar>
       

        </div>  

</el-card>
    
        
  



    <div class="child_div2" >


<!-- Loading progress -->
<div v-if="true" id='progress-bar-container'  class='progress-bar-container'>
<div> <img src="/icons/orientalcastlelogoanimated.gif" width="128" height="128"> </div>
<label class="label" for='progress-bar'> Loading... </label> 

<div id="progress-text" style="color:white;text-color:white;">%</div>


<progress id="progress-bar" value="0" max="100" ></progress>





</div>

<!-- Loading progress -->

<div style="width:100%; height:100%; margin-top:0; margin-left:0;  flex-direction: column;justify-content: center;align-items: center;">

<div id="canvas" style=" position:absolute;left:0;top:0;  "></div>
</div>



</div> <!-- child_div -->


</template>

<style scoped>


progress::-moz-progress-bar {background: #F78F1E}
progress::-webkit-progress-value { background: #F78F1E}
progress { background: #F78F1E; }

.el-collapse-item__header.el-collapse-item__header { 
  text-align:center;
  align-content: center;
   display: block;

}
.el-collapse-item__header {

  text-align:center;
  align-content: center;
   display: block;

}
.el-collapse {
  --el-collapse-header-bg-color: #ffefde;
 /* 
 --el-collapse-header-text-color: #ffffff;
 */
 }
.parent_div {
  pointer-events:auto;  
  z-index: 2;
}
.child_div1 {
  pointer-events:auto;
  z-index: 1;
}

.child_div2 {
  pointer-events:auto;
  z-index: 1;
}


@viewport {
  width: device-width ;
  zoom: 1.0 ;
} 

.progress-bar-container{
    
    font-size:16pt;
    position : absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
    color:white;
    display : flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title{
        font-size:20pt;
  }

    #progress-text{
        font-size:16pt;
     }

    #progress-bar {
         width:30%;
         margin-top: 0.5%;
         height: 2%;
         font-size:2vw;
         
    }
    .label {
         color:white;
         font-size:20pt;
 
    
    }


  .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}


</style>



<script>


import { threejs1 } from './threejs1.js'
import cameralookatdb from '../internaldb/cameralookat_db.js'
import selectedmenudb from '../internaldb/selectedmenu_db.js'
import { ref } from 'vue'
import {
  Document,
  Menu as IconMenu,
  Location,
  Setting,
} from '@element-plus/icons-vue'


export default {
setup(){

  return {

   
  }


},
  data(){
  

  return {
    deviceType: null,
      cameralookat: cameralookatdb,
      cameralookatdb:cameralookatdb,
      isMainmenu : ref(false),
      isFooter : ref(false),
      platform : null,
      isCollapse : ref(false),
      activeName : ref('1'),
      selectedMenu : '',
      selectedmenus : selectedmenudb,
      leftmenu : ref(false),
      descVisible : '',
  }
      
  }, 
mounted(){
  this.setDeviceType();
    threejs1();

},
methods : {

  closeDesc(){
    this.descVisible = 'false';
//this.selectedmenus = '';



console.log("closeDesc called")
},

aboutUs(){

this.selectedmenus = 'About Us';
console.log("aboutUs called")
},


ourBusiness(){

this.selectedmenus = 'Our Businesses';
console.log("aboutUs called")
},
  
  
  handleClick(){
    this.descVisible = '';
    //this.isCollapse = !this.isCollapse
console.log("handleclick this.selectedmenus= "+this.selectedmenus)
    if(this.selectedmenus == "")
    {this.leftmenu = !this.leftmenu}
    else {

      this.camerachange("orientalhq")
      this.leftmenu = !this.leftmenu
    }

  },

  applicationClick(){
    this.descVisible = '';
//this.isCollapse = !this.isCollapse
console.log("application this.selectedmenus= "+this.selectedmenus)
if(this.selectedmenus == "")
{this.camerachange("orientalhq")
  this.leftmenu = false
}else {

this.camerachange("orientalhq")
this.leftmenu = false
}


},

gotomainwebsiteClick(){

  window.open("https://orientalcastle.com/");

},
  handleOpen(key,keyPath){

    console.log(key, keyPath)

  },

  handleClose (key,keyPath){

    console.log(key, keyPath)

  },
  
  setDeviceType() {
                 //   this.platform = navigator.userAgent.;
                     if (navigator.userAgent.match(/Android/i)
                      || navigator.userAgent.match(/webOS/i)
                      || navigator.userAgent.match(/iPhone/i)
                      || navigator.userAgent.match(/iPad/i)
                      || navigator.userAgent.match(/iPod/i)
                      || navigator.userAgent.match(/BlackBerry/i)
                      || navigator.userAgent.match(/Windows Phone/i)) {
                        this.deviceType = 'mobile';
                    } else if (navigator.userAgent.match(/mac|win|linux/i)) {
                        this.deviceType = 'desktop';
                    } else if (navigator.userAgent.match(/tablet|ipad/i)) {
                        this.deviceType = 'tablet';
                    } else {
                        this.deviceType = 'unknown';
                    }
                },


  camerachange(value){
    this.cameralookatdb = value;
      this.cameralookat = value;
      this.leftmenu =false;

switch(value) {
  case "warehouse":
  this.selectedMenu = "Warehouse"
    break;

  case "factory":
  this.selectedMenu = "Factory"
    break;

  case "orientalhq":
  this.selectedMenu = "Oriental Castle HQ"
    break;



  case "shoppingmall":
  this.selectedMenu = "Shopping Mall"
    break;

  case "construction":
  this.selectedMenu = "Construction Site"
    break;

  case "powerstation":
  this.selectedMenu = "Power Plant"
    break;

  case "house":
  this.selectedMenu = "House"
    break;

  case "trainstation":
  this.selectedMenu = "Train Station"
    break;

 case "hospital":
  this.selectedMenu = "Hospital"
    break;

  default:
     this.selectedMenu = ""
} 

  //  this.isMainmenu = !this.isMainmenu;
   // this.isFooter = !this.isFooter;
     
  },


}






}
</script>
