import * as THREE from 'three';
import gsap from 'gsap';
import { ref } from 'vue';

import { LoadingManager, VSMShadowMap } from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { SAOPass } from 'three/addons/postprocessing/SAOPass.js';
import { GUI } from 'three/addons/libs/lil-gui.module.min.js';
//import { UnrealBloomPass } from 'three/addons/postprocessing/UnrealBloomPass.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import cameralookatdb from '../internaldb/cameralookat_db.js'
import selectedmenudb from '../internaldb/selectedmenu_db.js'

import camerastopspindb from '../internaldb/camerastopspin_db.js'
import { LUTPass } from 'three/addons/postprocessing/LUTPass.js';
import { LUTCubeLoader } from 'three/addons/loaders/LUTCubeLoader.js';
import { LUT3dlLoader } from 'three/addons/loaders/LUT3dlLoader.js';
import { OutputPass } from 'three/addons/postprocessing/OutputPass.js';
import  Stats  from 'three/examples/jsm/libs/stats.module.js';
import { componentSizeMap } from 'element-plus';


export function threejs1(){



    //clear memory
    THREE.Cache.clear();


   
    //create scene
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera( 50, window.innerWidth / window.innerHeight, 0.1, 1000 );
    const HemiLight = new THREE.HemisphereLight(0xffffff, 0xb1e2f4, 0.797);
  
   //Create a DirectionalLight
         //   const Directionallight = new THREE.DirectionalLight( 0xffffff, 2 );
        
          //  Directionallight.position.set( 0, 1, 0 );
        //    Directionallight.castShadow = true; // default false
        
            
            //Set up shadow properties for the light
       //     Directionallight.shadow.mapSize.width = 512 * 4; // default
         //   Directionallight.shadow.mapSize.height = 512 * 4; // default
          //  Directionallight.shadow.camera.near = 0.05; // default
          //  Directionallight.shadow.camera.far = 500000; // default




   const spotLight = new THREE.SpotLight(0xffFFFF,2000)
    const offset = new THREE.Vector3();
    const distance =7;
    //let cameralookat = JSON.parse(JSON.stringify(cameralookatdb.value));
    let cameralookat = cameralookatdb;
    let selectedmenus = selectedmenudb;


    
    console.log('cameralookatdb.value = '+cameralookatdb.value);
    console.log('cameralookat = '+cameralookat);


    //light settings
    spotLight.castShadow = true;
    spotLight.shadow.bias = -0.0001;
    spotLight.shadow.mapSize.width = 1024 * 4;
   spotLight.shadow.mapSize.height = 1024 * 4;
  



    // remember these initial values
    var tanFOV = Math.tan( ( ( Math.PI / 180 ) * camera.fov / 2 ) );
    var windowHeight = window.innerHeight;


    //camera settings
      //camera.position.set(0,0,0);
    //camera.lookAt(0,0,0);


    //set canvas to div
    var canvas = document.getElementById('canvas');


    let stats;
    let raycaster;
    stats = new Stats();
   // canvas.appendChild(stats.dom);



    //declare loading
    var totalSize = 15073008;

    const loadingManager = new THREE.LoadingManager();
    const GLTF_loader = new GLTFLoader(loadingManager);
    let progressEmulate = false;
    const dLoader = new DRACOLoader();
    dLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/');
    dLoader.setDecoderConfig({type:'js'});
    GLTF_loader.setDRACOLoader(dLoader);
    const progressBar = document.getElementById('progress-bar');
    const progressText = document.getElementById('progress-text');
    const progressBarContainer =  document.getElementById('progress-bar-container') ;
   
    //declare raycaster
    raycaster = new THREE.Raycaster();
    const pointer = new THREE.Vector2();

    //declare renderer    
    const renderer = new THREE.WebGLRenderer({ alpha: true, maxLights: 30 });
    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.setClearColor( 0x000000, 0);
 //  renderer.toneMapping = THREE.CineonToneMapping;
   renderer.toneMapping = THREE.ACESFilmicToneMapping;
    //renderer.toneMapping = THREE.ReinhardToneMapping;
    renderer.toneMappingExposure = 1.2065;    
    //renderer.shadowMap.type = THREE.PCFSoftShadowMap;
   // renderer.shadowMap.type = THREE.VSMShadowMap;
    renderer.shadowMap.enabled =true;
 
renderer.shadowMap.type = THREE.PCFSoftShadowMap; 
 //   renderer.useLegacyLights = true;

    canvas.appendChild(renderer.domElement);
    //postprocessing
  
    const paramsLUT = {
        enabled: false,
        lut: 'Clayton 33.CUBE',
        intensity: 0.447,
        use2DLut: false,
    };

    const lutMap = {
        'Bourbon 64.CUBE': null,
        'Chemical 168.CUBE': null,
        'Clayton 33.CUBE': null,
        'Cubicle 99.CUBE': null,
        'Remy 24.CUBE': null,
        'Presetpro-Cinematic.3dl': null
    };



    let lutPass;

    lutPass = new LUTPass();



    let saoPass;
	
    saoPass = new SAOPass( scene, camera, false, true );
    const params = {
        exposure: 1.2,
       // bloomStrength: 0,
      //  bloomThreshold: 0.309,
       // bloomRadius: 0.07,
        SpotLightintensity: 1000.0,
        HemiIntensity : 1.497,

    };
  

    //const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
  
  
    //bloomPass.threshold = params.bloomThreshold;
   // bloomPass.strength = params.bloomStrength;
    //bloomPass.radius = params.bloomRadius;

    
    let composer;
	composer = new EffectComposer( renderer );

    const outputPass = new OutputPass();
  //  composer.addPass( outputPass );

   // composer.addPass( new OutputPass() );
    //composer.addPass( lutPass );

    const renderScene = new RenderPass( scene, camera );
   composer.addPass( renderScene );
   // composer.addPass( bloomPass );

  composer.addPass( new OutputPass() );
    composer.addPass( lutPass );

// SETTINGS FOR MATERIAL
    const settings = {
        metalness: 1.0,
        roughness: 0.4,
        ambientIntensity: 0.2,
        aoMapIntensity: 1.0,
        envMapIntensity: 1.0,
        displacementScale: 2.436143, // from original model
        normalScale: 1.0,
        clearcoatRoughness : 1.0
    };
// SETTINGS END

let envmap; 

    // material START
    let material;
    material = new THREE.MeshStandardMaterial( {

        color: 0x888888,
        roughness: settings.roughness,
        metalness: settings.metalness,

       // normalMap: normalMap,
        normalScale: new THREE.Vector2( 1, - 1 ), // why does the normal map require negation in this case?

       // aoMap: aoMap,
        aoMapIntensity: 1,

       // displacementMap: displacementMap,
        displacementScale: settings.displacementScale,
        displacementBias: - 0.428408, // from original model

        //envMap: envmap,
        envMapIntensity: settings.envMapIntensity,

        side: THREE.DoubleSide

    } );

    // MATERIAL END



    
    // car material START
    let car_material;
    car_material = new THREE.MeshStandardMaterial( {

        color: 0x888888,
        roughness: settings.roughness,
        metalness: settings.metalness,

       // normalMap: normalMap,
        normalScale: new THREE.Vector2( 1, - 1 ), // why does the normal map require negation in this case?

       // aoMap: aoMap,
        aoMapIntensity: 1,

       // displacementMap: displacementMap,
        displacementScale: settings.displacementScale,
        displacementBias: - 0.428408, // from original model

        //envMap: envmap,
        envMapIntensity: settings.envMapIntensity,

        side: THREE.DoubleSide

    } );

    // car_material END

/*
    let gui;
	gui = new GUI();
				gui.width = 350;
				gui.add( paramsLUT, 'enabled' );
				gui.add( paramsLUT, 'lut', Object.keys( lutMap ) );
				gui.add( paramsLUT, 'intensity' ).min( 0 ).max( 1 );


          
                if ( renderer.capabilities.isWebGL2 ) {

					gui.add( paramsLUT, 'use2DLut' );

				} else {

					paramsLUT.use2DLut = true;

				}
*/

    //gui
    // Init gui
//    const gui = new GUI();
/*
    gui.add( saoPass.params, 'output', {
        'Beauty': SAOPass.OUTPUT.Beauty,
        'Beauty+SAO': SAOPass.OUTPUT.Default,
        'SAO': SAOPass.OUTPUT.SAO,
        'Depth': SAOPass.OUTPUT.Depth,
        'Normal': SAOPass.OUTPUT.Normal
    } ).onChange( function ( value ) {

        saoPass.params.output = parseInt( value );

    } );
    gui.add( saoPass.params, 'saoBias', - 1, 1 );
    gui.add( saoPass.params, 'saoIntensity', 0, 1 );
    gui.add( saoPass.params, 'saoScale', 0, 50 );
    gui.add( saoPass.params, 'saoKernelRadius', 1, 100 );
    gui.add( saoPass.params, 'saoMinResolution', 0, 1 );
    gui.add( saoPass.params, 'saoBlur' );
    gui.add( saoPass.params, 'saoBlurRadius', 0, 200 );
    gui.add( saoPass.params, 'saoBlurStdDev', 0.5, 150 );
    gui.add( saoPass.params, 'saoBlurDepthCutoff', 0.0, 0.1 );

    
    gui.add( params, 'HemiIntensity', 0.1, 2 ).onChange( function ( value ) {

        HemiLight.intensity = Math.pow( value, 4.0 );
 
     } );
 

    gui.add( params, 'SpotLightintensity', 0.1, 2 ).onChange( function ( value ) {

       spotLight.intensity = Math.pow( value, 4.0 );

    } );


    gui.add( params, 'exposure', 0.1, 2 ).onChange( function ( value ) {

        renderer.toneMappingExposure = Math.pow( value, 4.0 );
        renderer.toneMapping = THREE.ACESFilmicToneMapping;
    } );

    gui.add( params, 'bloomThreshold', 0.0, 1.0 ).onChange( function ( value ) {

        bloomPass.threshold = Number( value );

    } );

    gui.add( params, 'bloomStrength', 0.0, 3.0 ).onChange( function ( value ) {

        bloomPass.strength = Number( value );

    } );

    gui.add( params, 'bloomRadius', 0.0, 1.0 ).step( 0.01 ).onChange( function ( value ) {

        bloomPass.radius = Number( value );

    } );




    //METAL SETITNG

    gui.add( settings, 'metalness' ).min( 0 ).max( 1 ).onChange( function ( value ) {

        material.metalness = value;

    } );

    gui.add( settings, 'roughness' ).min( 0 ).max( 1 ).onChange( function ( value ) {

        material.roughness = value;

    } );

    gui.add( settings, 'aoMapIntensity' ).min( 0 ).max( 1 ).onChange( function ( value ) {

        material.aoMapIntensity = value;

    } );

    gui.add( settings, 'envMapIntensity' ).min( 0 ).max( 3 ).onChange( function ( value ) {

        material.envMapIntensity = value;

    } );

    gui.add( settings, 'displacementScale' ).min( 0 ).max( 3.0 ).onChange( function ( value ) {

        material.displacementScale = value;

    } );

    gui.add( settings, 'normalScale' ).min( - 1 ).max( 1 ).onChange( function ( value ) {

        material.normalScale.set( 1, - 1 ).multiplyScalar( value );

    } );

    //metal SETTING END


    
    //car_material SETITNG

    gui.add( settings, 'metalness' ).min( 0 ).max( 1 ).onChange( function ( value ) {

        car_material.metalness = value;

    } );

    gui.add( settings, 'roughness' ).min( 0 ).max( 1 ).onChange( function ( value ) {

        car_material.roughness = value;

    } );

    gui.add( settings, 'aoMapIntensity' ).min( 0 ).max( 1 ).onChange( function ( value ) {

        car_material.aoMapIntensity = value;

    } );

    gui.add( settings, 'envMapIntensity' ).min( 0 ).max( 3 ).onChange( function ( value ) {

        car_material.envMapIntensity = value;

    } );

    gui.add( settings, 'displacementScale' ).min( 0 ).max( 3.0 ).onChange( function ( value ) {

        car_material.displacementScale = value;

    } );

    gui.add( settings, 'normalScale' ).min( - 1 ).max( 1 ).onChange( function ( value ) {

        car_material.normalScale.set( 1, - 1 ).multiplyScalar( value );

    } );

    //car_material SETTING END


    

    SAOPass.OUTPUT.Beauty = true;
    bloomPass.threshold = .309;
    bloomPass.strength = 0;
    bloomPass.radius = .07;
    
    */
   // HemiLight.intensity = 0.99;
   // HemiLight.intensity = 4.6;


/*
    gui.add( saoPass.params, 'saoScale', 0, 50 );
    gui.add( saoPass.params, 'saoKernelRadius', 1, 100 );
    gui.add( saoPass.params, 'saoMinResolution', 0, 1 );
    gui.add( saoPass.params, 'saoBlur' );
    gui.add( saoPass.params, 'saoBlurRadius', 0, 200 );
    gui.add( saoPass.params, 'saoBlurStdDev', 0.5, 150 );
    gui.add( saoPass.params, 'saoBlurDepthCutoff', 0.0, 0.1 );



    saoPass.params.saoIntensity  = 0.103;
    saoPass.params.saoScale  = 5.15;
    saoPass.params.saoKernelRadius = 3.861;
    saoPass.params.saoMinResolution  = 0.0004;
    saoPass.params.saoBlur =  true;
    saoPass.params.saoBlurRadius = 15.6;
    saoPass.params.saoBlurStdDev = 26.91;
    saoPass.params.saoBlurDepthCutoff = 0.8094;
    composer.addPass( saoPass );
*/
   // saoPass.params.saoIntensity  = 0.078;
  




   // saoPass.params.saoKernelRadius  = 6.336;
   // saoPass.params.saoMinResolution  = 0;
    //saoPass.params.saoBlurRadius = 0;
   // saoPass.params.saoBlurStdDev = 0.5;

  //  window.addEventListener( 'resize', onWindowResize );
  //canvas.addEventListener('resize',onWindowResize);

    //orbitcontrols
    const controls = new OrbitControls(camera, renderer.domElement)
    controls.minPolarAngle = 0; // radians
   // controls.maxPolarAngle = Math.PI; // radians
    controls.maxPolarAngle = Math.PI/2 -.1; 

    /*
    controls.minAzimuthAngle = Math.PI * -.35;
    controls.maxAzimuthAngle = Math.PI * .35;
*/
controls.minAzimuthAngle = - Infinity; //radians
controls.maxAzimuthAngle = Infinity; // radians

    //3d object link    
    const url = '/3dmodels/Oriental_city_newconcept.glb'


    //Water Plane

    const textureLoader = new THREE.TextureLoader();

    const waterBaseColor = textureLoader.load("/3dmodels/textures/water/Water_002_COLOR.jpg");
    const waterNormalMap = textureLoader.load("/3dmodels/textures/water/Water_002_NORM.jpg");
    const waterHeightMap = textureLoader.load("/3dmodels/textures/water/Water_002_DISP.png");
    const waterAlphaMap = textureLoader.load("/3dmodels/textures/water/Water_002_alphamap.jpg");
    const waterRoughness = textureLoader.load("/3dmodels/textures/water/Water_002_ROUGH.jpg");
    const waterAmbientOcclusion = textureLoader.load("/3dmodels/textures/water/Water_002_OCC.jpg");
 // width 6, height 4
    const WIDTH = 38;
    const HEIGHT = 18;
    const geometry = new THREE.BufferGeometry(WIDTH, HEIGHT, 50, 50);
    const plane = new THREE.Mesh(geometry, 
      new THREE.MeshStandardMaterial({ 
          map: waterBaseColor, 
          transparent : true,
          alphaMap : waterAlphaMap,
          normalMap: waterNormalMap, 
          displacementMap: waterHeightMap, displacementScale: .5, 
          roughnessMap: waterRoughness, roughness: 0, 
          aoMap: waterAmbientOcclusion }));

   
    plane.receiveShadow = true;
    plane.castShadow = true;


    // call loading function
    loading(url);

    let generator = new THREE.PMREMGenerator(renderer);


//RGBE loader start
    new RGBELoader()
    .load('/3dmodels/textures/hdr/royal_esplanade_1k.hdr', function (texture){

        envmap = generator.fromEquirectangular(texture);

        texture.mapping = THREE.EquirectangularReflectionMapping;  
    //    scene.background = texture;
  //  scene.background = new THREE.Color( 0x000000, 0.0 );
   renderer.setClearColor(0x000000, 0.0);
                scene.enviroment = texture; 
                scene.background = null;
               
                composer.render();
		//		renderer.render( scene, camera );



        Object.keys( lutMap ).forEach( name => {

            if ( /\.CUBE$/i.test( name ) ) {

                new LUTCubeLoader()
                    .load( 'luts/' + name, function ( result ) {

                        lutMap[ name ] = result;

                    } );

            } else {

                new LUT3dlLoader()
                    .load( 'luts/' + name, function ( result ) {

                        lutMap[ name ] = result;

                    } );

            }

        } );



// GLTF Loader Start
    GLTF_loader.load( url,
        
   
        function ( gltf ) {

       
        console.log(gltf)


        //get materials from gltf start
       

        gltf.parser.getDependencies( 'material' ).then( ( materials ) => {

            console.log( materials );
           // materials..getObjectByName('camera_gasketalley').position 
       
           materials.forEach(element => {
            
          //  console.log('material element = '+ element.name );

            if(element.name == "metal")
            {
              
              material =  element;
              element.envMap = texture;
              element.metalness = .963;
              element.roughness = .263;
              element.aoMapIntensity = 3;
            }else if(element.name == "main_gray") {

           //     element.envMap = texture;
               // element.metalness = .963;
            //    element.roughness = .263;
               // element.aoMapIntensity = 5;



            }else if(element.name == "road_texture") {

                //     element.envMap = texture;
                    // element.metalness = .963;
                 //    element.roughness = .263;
                    // element.aoMapIntensity = 5;
     
     
     
            }else if(element.name == "pillar_white") {

                    //     element.envMap = texture;
                        // element.metalness = .963;
                     //    element.roughness = .263;
                        // element.aoMapIntensity = 5;
         
         
         
            }else {

                element.envMap = texture;
               // element.metalness = .963;
            //    element.roughness = .263;
                element.aoMapIntensity = 5;



            }

           });
        } );

               //get materials from gltf end

         

        const model = gltf.scene;
        scene.add( model );
        const mesh = scene.children[ 0 ];


       //animation in glb file
    //   let mixer;

      // mixer = new THREE.AnimationMixer( model );
    //   mixer.clipAction( gltf.animations[ 0 ] ).play();
     const clock = new THREE.Clock();
       let mixer = new THREE.AnimationMixer(model);   
       const clips = gltf.animations;
       clips.forEach(function(clip){
           console.log("mixer == "+mixer.clipAction(clip));
               const action = mixer.clipAction(clip);
               action.play();


           }); 
          // animate();





      //  Directionallight.position.copy = mesh.getObjectByName('sun_position').position;

      //  Directionallight.target.copy = mesh.getObjectByName('Cube').position;
      //  scene.add( Directionallight );
      //  scene.add( Directionallight.target );
      //spotLight.position.copy = mesh.getObjectByName('sun_position').position;
      //spotLight.target.copy = mesh.getObjectByName('Cube').position;
   //  scene.add( spotLight);


let INTERSECTED;
let SELECTED;  
let SELECTEDNAME;  
let SELECTEDARRAY;
let SELECTED2 = false; 
let MENUCLICKED;    
     //   canvas.addEventListener( 'mousemove', onPointerMove );
      //  canvas.addEventListener( 'pointerdown', onPointerDown );
     //   canvas.addEventListener( 'pointerdown', onPointerDown );
 /* 
        const tree_material =   mesh.getObjectByName('tree_1').material;
        const rock_material =   mesh.getObjectByName('rock_1').material;
        const logs_material =   mesh.getObjectByName('logs').material;
        const ground_material =   mesh.getObjectByName('ground').material;   
*/
        function onPointerMove( event ) {
           // const mesh = scene.children[ 0 ];
            pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
            pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
        
      
       let theta = 0;
       theta += 0.1;
       const radius = 5;
  
    
        raycaster.setFromCamera( pointer, camera );
        
    
        const highlight_material = new THREE.MeshBasicMaterial( { color: 0xdadada } );

  
                //const intersects = raycaster.intersectObjects( scene.children, false );
              //  const intersects = raycaster.intersectObjects( scene.children, true );
    
           //   const intersects_tree = raycaster.intersectObjects(mesh.getObjectByName('tree_1'), true);
              const intersects = raycaster.intersectObjects(mesh.children, true);
           //   console.log("material :   "+mesh.getObjectByName('tree_1').material);
    
    
             
              if ( intersects.length > 0 ) {
              
    
                

            //    console.log("intersects : "+intersects[0].object.parent.name);
              //  console.log("mouse in");
              /*
                
                mesh.getObjectByName('tree_1').material = highlight_material;
                mesh.getObjectByName('tree_2').material = highlight_material;
                mesh.getObjectByName('logs').material = highlight_material;
                mesh.getObjectByName('rock_1').material = highlight_material;
                mesh.getObjectByName('rock_2').material = highlight_material;
                mesh.getObjectByName('rock_3').material = highlight_material;
                mesh.getObjectByName('rock_4').material = highlight_material;
                mesh.getObjectByName('rock_5').material = highlight_material;
                mesh.getObjectByName('ground').material = highlight_material;
    
    */          INTERSECTED = intersects[0].object;
                //INTERSECTED.currentHex = INTERSECTED.material.emissive.getHexString();
               // intersects[0].object.material.emissive.setHex( 0xff0000 );
            
           

              }else  {
                
              //  console.log("mouse out "+INTERSECTED.parent.name );
               // console.log("currentHex "+INTERSECTED.currentHex );
               // mesh.getObjectByName(INTERSECTED.name).material.emissive.setHex( 0x000000  );


              }
       
    
        }
    
function onPointerDown( event ) {
    
            if(event.button == 0)
            {

               
                pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
                pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

          
                let theta = 0;
                theta += 0.1;
                const radius = 5;


                raycaster.setFromCamera( pointer, camera );


              //  const highlight_material = new THREE.MeshBasicMaterial( { color: 0xdadada } );


                const intersects = raycaster.intersectObjects(mesh.children, true);
                let control_target = '';
                let camera_target = '';

                //if(intersects.length > 0 && SELECTED != intersects[0].object.parent.name)

                console.log("status : "+intersects.length);
                SELECTEDARRAY = intersects[0].object.name.split("_");

                if ( intersects.length > 0 && SELECTEDARRAY[1] != 'noclick') {
                
                  //  SELECTEDARRAY = intersects[0].object.name.split("_");
                  
                    if(SELECTEDARRAY.slice(-1) == 1)
                    {   SELECTED = SELECTEDARRAY.slice(-1);
                        SELECTED[0] = SELECTEDARRAY[1];
                    }else { 
                     SELECTED = SELECTEDARRAY.slice(-1); }
                  

                   console.log("currently clicked : "+intersects[0].object.name);
                   console.log("currently SELECTEDARRAY : "+SELECTEDARRAY);
                   console.log("currently SELECTEDslice : "+SELECTED);
              
                            
                        if(SELECTED != 1 && SELECTED != 2 && SELECTEDARRAY[1] != 'noclick' ){
                              if(intersects[0].object.material.opacity != 0)
                                {
                                    console.log("Mesh is opaque ");

                                    // if(SELECTED != SELECTEDNAME){
                                    if(SELECTED[0] != SELECTEDNAME ){

                                       // MENUCLICKED =  cameralookat.value;
                                        cameralookat.value =  SELECTED;
                                  
                                        console.log("cameralookat.value "+cameralookat.value);

                                        console.log("SELECTED : "+SELECTED);

                                        //check last click 
                                        console.log("SELECTEDNAME : "+SELECTEDNAME);

                                     // console.log("intersects : "+intersects[0].object.parent.name);

                                    // control_target = intersects[0].object.parent.name+'_target';
                                    // camera_target = intersects[0].object.parent.name+'_camera';

                                        control_target = SELECTED+'_target';
                                        camera_target = SELECTED+'_camera_closeup';


                                        SELECTEDNAME = SELECTED;

                                        SELECTED2 = false;

                                        }else if(SELECTED[0] == SELECTEDNAME  && SELECTED2 != true && SELECTEDARRAY[1] != 'noclick') {

                                          //  SELECTED2 = true;
                                                    // same object clicked 2nd time
                                                    console.log(SELECTED[0]+" clicked 2nd time");
                                                   
                                                    cameralookat.value =  SELECTED;
                                                    MENUCLICKED =  cameralookat.value;
                                                    SELECTED2 = true;
                                        

                                        }else if(SELECTED[0] == SELECTEDNAME && SELECTED2 == true && SELECTEDARRAY[1] != 'noclick') {
                                            
                                            
                                            // same object clicked 3rd time
                                            console.log(SELECTEDNAME+" clicked 3rd time");

                                          
                                                      // MENUCLICKED =  cameralookat.value;
                                        cameralookat.value =  SELECTED;
                                  
                             
                                        control_target = SELECTED+'_target';
                                        camera_target = SELECTED+'_camera_closeup';

                                        SELECTEDNAME = SELECTED;

                                        SELECTED2 = false;
  

                            }

                                    }
                                } else {
                                    console.log("Mesh is transparent ");

                                }
                        

                                }
                             





                }
                
    
        }






        scene.add( HemiLight );
      //  scene.add( spotLight );
      //  scene.add(plane);



        
        mesh.position.z = 0;
        mesh.position.y = 0;




        mesh.traverse(n=>{

          // console.log(n.name+" isMesh : "+n.isMesh) 

            if(n.isMesh){
      
           
          //  n.castShadow = true;
            n.receiveShadow = true;
            if(n.material.map) n.material.map.anisotropy = 16;

            }else if(n.isSpotLight){
                
                spotLight.position.set(

                    mesh.getObjectByName('orientalhq_camera').position.x +50,
                    mesh.getObjectByName('orientalhq_camera').position.y +50,
                    mesh.getObjectByName('orientalhq_camera').position.z -10 ,
                    );
                    
                    spotLight.intensity = 20000;

                 //   spotLight.shadow.camera.near = 0.5; // default
                    spotLight.shadow.camera.far = 60000; // default
                    spotLight.shadow.focus = 1; // default
                 //   spotLight.shadow.bias = .00001;
                    spotLight.shadow.mapSize.width = 2048 * 4;
                    spotLight.shadow.mapSize.height = 2048 * 4;
                    
                    
                    scene.add( spotLight );
   
                console.log("Spotlight true = "+n.id)
                    //Spotlight_builtin settings
                   n.castShadow = true;
                 // n.intensity = 30000;
                   n.intensity = 0;
            
                  n.shadow.camera.near = 0.5; // default
                   n.shadow.camera.far = 50000; // default
                   n.shadow.focus = 1; // default
                   n.shadow.bias = -0.001;
                  n.shadow.mapSize.width = 1024 * 4;
                   n.shadow.mapSize.height = 1024 * 4;
                   
            }else if(n.isPointLight){

                //Spotlight_builtin settings
              //  n.castShadow = true;
             //   n.distance = 10;
             //  n.shadow.bias = -0.0001;
              //  n.shadow.mapSize.width = 1024 * 4;
            //   n.shadow.mapSize.height = 1024 * 4;

        }

            })


    window.addEventListener( 'resize', onWindowResize, false );

    function onWindowResize( event ) {

    camera.aspect = window.innerWidth / window.innerHeight;
    
    // adjust the FOV
    camera.fov = ( 360 / Math.PI ) * Math.atan( tanFOV * ( window.innerHeight / windowHeight ) );
    
    camera.updateProjectionMatrix();
    //camera.lookAt( scene.position );

    renderer.setSize( window.innerWidth, window.innerHeight );

    composer.render();
   // renderer.render( scene, camera );
    
}





function chosenMenu(){

    
        if(cameralookat.value == 'start')
        {
            
            
            if(cameralookat.value != 'default')
            {
            
                canvas.appendChild( renderer.domElement );
        
                controls.target.copy( mesh.getObjectByName('orientalhq_target_real').position);
                camera.position.copy( mesh.getObjectByName('orientalhq_camera').position );  
               camera.lookAt( mesh.getObjectByName('orientalhq_target_real').position );

     




            
                cameralookat.value = 'default'
            }

        }else if(cameralookat.value != 'default' &&  MENUCLICKED != cameralookat.value){


            console.log("cameralookat.value "+cameralookat.value);
            console.log("menu clicked 1st time ");


 //return transparent object to opaque
if(cameralookat.value == 'orientalhq' || cameralookat.value == 'orientalhqhome'){
    console.log("cameralookatvalue = "+ cameralookat.value);
                
        mesh.traverse(n=>{

            if(n.isMesh ) 
            {
                    if ( n.material.name.search("blueprint_material_overall") == 0 ){
                            
                        n.visible = false; 
                    
                        n.material.transparent = true;  
                        n.material.opacity = .2;

                    } else if ( n.material.name.search("white_material_overall_planeground") == 0 ){

                        n.visible = true;   

                    } else  if(n.material.name != 'water' && n.material.name != 'png#1_orbit') { 
                    

                        // n.material.transparent = false;
                        // n.material.opacity = 1;

                        n.visible = true;

                            if(n.material.name.search('png#1_orbit')!=null)
                            {
                        
                        //      n.material.transparent = true;
                        //  n.material.opacity = 1;
                        n.visible = true;

                        }


                    } else if(n.material.name == 'water')  {
                        //  n.material.transparent = true;
                        //  n.material.opacity = .5;

                    n.visible = true;


                    }else if(n.material.name == 'png#1_orbit') {
                        n.visible = true;
                        /*
                        gsap.to(n.material, {
                        
                            opacity : 1,
                            duration : 1.5,
                        transparent : true,
                        // ease : "power3.inOut",

                        });
                */



                    }


          }

        });

 }
 //return transparent object to opaque


            let menu_camera_target = '';
            let menu_control_target = '';
            let menu_camera_position = '';
            
            if(cameralookat.value != "orientalhq"){
                menu_camera_position = cameralookat.value +"_camera_closeup";
                menu_camera_target = cameralookat.value +"_target";
                menu_control_target = cameralookat.value +"_target_real";
            }else if(cameralookat.value == "orientalhqhome"){
               // menu_camera_position = "orientalhq_camera_closeup";
                menu_camera_target = "orientalhq_target";
                menu_camera_position = "orientalhq_camera";
            }else {
              //  menu_camera_position = "orientalhq_camera_closeup";
                menu_camera_target = "orientalhq_target";
                menu_camera_position = "orientalhq_camera";
            }
           
         //   let SELECTEDMENU = cameralookat.value;
            selectedmenus.value  = cameralookat.value;



 if( mesh.getObjectByName(menu_control_target) != null &&  mesh.getObjectByName(menu_control_target) != 'undefined' &&  mesh.getObjectByName(menu_control_target) != ''  ){




    console.log("menu_control_target : "+menu_control_target);
    console.log("menu_camera_position: "+menu_camera_position);
    console.log("menu_camera_target : "+menu_camera_target);

            //controls.target.copy( mesh.getObjectByName(menu_camera_target).position);
         
            gsap.to(controls.target, {

                x : mesh.getObjectByName(menu_control_target).position.x,
                y : mesh.getObjectByName(menu_control_target).position.y,
                z : mesh.getObjectByName(menu_control_target).position.z,
                duration : 1.5,
           //   ease : "power3.inOut",

            })

           // camera.position.copy( mesh.getObjectByName(menu_camera_position).position );  
            gsap.to(camera.position, {

                x : mesh.getObjectByName(menu_camera_position).position.x,
                y : mesh.getObjectByName(menu_camera_position).position.y,
                z : mesh.getObjectByName(menu_camera_position).position.z,
                duration : 1.5,
              // ease : "power3.inOut",


            })

           // camera.lookAt( mesh.getObjectByName(menu_camera_target).position );
       
           console.log("meshgetobjectbyname = "+ mesh.getObjectByName(menu_camera_target).position.x);

    
           /*
          gsap.to(camera.lookAt, {

                x : mesh.getObjectByName(menu_camera_target).position.x,
                y : mesh.getObjectByName(menu_camera_target).position.y,
                z : mesh.getObjectByName(menu_camera_target).position.z,
                duration : 3,
              // ease : "power3.inOut",


            })

         */


            MENUCLICKED =  cameralookat.value;
            selectedmenus.value = MENUCLICKED;
           // cameralookat.value =  'default';
      
            }
            
            
            if(cameralookat.value != "orientalhq" && cameralookat.value != "port" && cameralookat.value != "petrotankplant" && cameralookat.value != "jetty" && cameralookat.value != "orientalhqhome" && cameralookat.value != "landreclaim"  && cameralookat.value != "river")
            {
      
                    
                                //transparent everything except chosen menu
                                mesh.traverse(n=>{
                    
                                    if(n.isMesh ) 
                                    {
                                        //   console.log("n.material.name.search(white_material_overall_planeground) =="+n.material.name.search('white_material_overall_planeground'));
                                        if ( n.material.name.search("blueprint_material_overall") == 0 ){
                        
                                            n.visible = true; 
                                           
                                            n.material.transparent = true;  
                                            n.material.opacity = .2;
  
                                         } else if ( n.material.name.search("white_material_overall_planeground") == 0 ){
                        
                                              n.visible = false;   
    
                                         } else if( n.material.name.search(selectedmenus.value) == -1  )
                                        {
                     
                                        
       
                                                // n.material.transparent = true;
                                                // n.material.opacity = 0;
                                                n.visible = false;
                             
                                        }else if ( n.material.name.search("warehouse") != -1 && selectedmenus.value == "house" ){
                    
                                                        //  console.log(" material name "+ n.material.name);
                                                    //   n.material.transparent = true;
                                                    //   n.material.opacity = 0;
                                    
                                                    n.visible = false;   
                    
                                        }else if ( n.material.name.search("ospfactory") != -1 && selectedmenus.value == "factory" ){
                    
                                                    //  console.log(" material name "+ n.material.name);
                                                //   n.material.transparent = true;
                                                //   n.material.opacity = 0;
                                
                                         n.visible = false;   
                        
                                            }
                    
                                    }
                    
                            });

                            cameralookat.value =  'default';
                            MENUCLICKED = null;
                    
        
        
        
                        }



        }
    }





    function animate() {
   
        controls.update();

        lutPass.enabled = paramsLUT.enabled && Boolean( lutMap[ paramsLUT.lut ] );
        lutPass.intensity = paramsLUT.intensity;
        if ( lutMap[ paramsLUT.lut ] ) {

            const lut = lutMap[ paramsLUT.lut ];
            lutPass.lut = paramsLUT.use2DLut ? lut.texture : lut.texture3D;

        }
        composer.render();
      //  renderer.render( scene, camera );
      //  composer.render();
       if(mixer)
       {
         
          mixer.update(clock.getDelta());
       }
    


                requestAnimationFrame( animate );
                composer.render();
                stats.update();
                chosenMenu();
       }

    
       animate();
    },
    
         
        
          // called while loading
          function (xhr) {
            if(xhr.total == 0)
            {
            //   totalSize = 75555000;
             totalSize = 12367000;

            }
            else
            {
                totalSize = xhr.total;

            }

         //   console.log('Ajax object data', xhr);
          //  console.log('Ajax object', (xhr.loaded / totalSize * 100) + '% loaded');


          
            progressBar.value = ((xhr.loaded / totalSize) * 100).toFixed(2);
            progressText.innerHTML = '<h5>' +((xhr.loaded / totalSize) * 100).toFixed(2)+ ' %  <br/>'+ ((xhr.loaded /1000)/1000).toFixed(2) +'MB / '+totalSize/100+' MB loaded </h5>';
   
                if(progressBar.value ==100)
                {
    
                    progressBarContainer.style.visibility = "hidden";
                }
        
        
        
        
        }

        
        
    
    
    );
    
    //GLTF Loader end

    });
    //RGBE loader end

   

















      




    function loading(url) {
   
        loadingManager.onStart = function(url, loaded, total){

        console.log("start loading")
        progressBar.value = ((loaded / total) * 100).toFixed(2);
        progressText.innerHTML = '<h5>' +((loaded / total) * 100).toFixed(2)+ ' %</h5>';

            if(progressBar.value <= 20 && progressEmulate == true)  
            {

                var loadingcounter = 0;
                window.setInterval(
                function () {
                    if(loadingcounter <= 50 && progressEmulate == true)  
                {  loadingcounter = loadingcounter + 1.51; }
                    progressText.innerHTML  = '<h5>' +loadingcounter.toFixed(2)+ ' %</h5>';
                    progressBar.value = loadingcounter.toFixed(2);
                }, 1000);
            }



    }

        
     loadingManager.onLoad = function(url, loaded, total){
        console.log("onload")
        progressBar.value = ((loaded / total) * 100).toFixed(2);
        progressText.innerHTML = '<h5>' +((loaded / total) * 100).toFixed(2)+ ' %</h5>';
        progressEmulate = false;
            if(progressBar.value ==100)
            {

                progressBarContainer.style.visibility = "hidden";
            }

     }

     loadingManager.onProgress= function(url, loaded, total){
        console.log("progress")
            progressEmulate = false;
            progressBar.value = ((loaded / total) * 100).toFixed(2);
            progressText.innerHTML = '<h5>' +((loaded / total) * 100).toFixed(2)+ ' %</h5>';

                if(progressBar.value ==100)
                {
                    progressEmulate = false;
                    progressBarContainer.style.visibility = "hidden";
                }

     }



    }

}