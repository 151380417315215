import { createApp } from 'vue'
//import PrimeVue from 'primevue/config';
//import './styles/element/index.scss'
import ElementPlus from 'element-plus'
import "./assets/fontstylesheet.css"

import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'

//theme
//import "primevue/resources/themes/lara-light-teal/theme.css"

//core
//import "primevue/resources/primevue.min.css";

//icons
import "primeicons/primeicons.css";

createApp(App)
.use(router)
//.use(PrimeVue, { ripple: true })
.use(ElementPlus)
.mount('#app');
